import React, { Component } from "react";
import "./App.css";
import InstructorApp from "./InstructorApp";
import { Slide } from "@mui/material";
import { SnackbarProvider } from "notistack";

import AppTheme from "./environment/client/themes/AppTheme";

class App extends Component {
  render() {
    return (
      <AppTheme>
        <SnackbarProvider
          // iconVariant={{ success: "✅", error: "✖️", warning: "⚠️", info: "ℹ️" }}
          // dense={false} preventDuplicate hideIconVariant={false}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          TransitionComponent={Slide}
          maxSnack={10}
        >
          <InstructorApp />
        </SnackbarProvider>
      </AppTheme>
    );
  }
}

export default App;
