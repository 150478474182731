import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  button: {
    minHeight: "auto",
    minWidth: "auto",
    //backgroundColor: theme.palette.primary.main,
    color: "#DDD",
    boxShadow:
      "0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12)",
    border: "none",
    borderRadius: "3px",
    position: "relative",
    padding: "12px 30px",
    margin: ".3125rem 1px",
    fontSize: "12px",
    fontWeight: "400",
    textTransform: "uppercase",
    letterSpacing: "0",
    willChange: "box-shadow, transform",
    transition:
      "box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
    lineHeight: "1.42857143",
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    touchAction: "manipulation",
    cursor: "pointer",
    "&:hover,&:focus": {
      color: "#FFFFFF",
      // backgroundColor: theme.palette.gray.main,
      boxShadow:
        "0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      display: "inline-block",
      top: "0",
      fontSize: "1.1rem",
      marginRight: "4px",
      verticalAlign: "middle"
    },
    "& svg": {
      position: "relative",
      display: "inline-block",
      top: "0",
      width: "18px",
      height: "18px",
      marginRight: "4px",
      verticalAlign: "middle"
    },
    "&$justIcon": {
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        marginRight: "0px",
        position: "absolute",
        width: "100%",
        transform: "none",
        left: "0px",
        top: "0px",
        height: "100%",
        lineHeight: "41px",
        fontSize: "20px"
      }
    },
  },
  fullWidth: {
    width: "100%"
  },
  primary: {
    "&.MuiButton-root": {
      backgroundColor: theme.palette.primary.main,
      boxShadow:
      "0 2px 2px 0 rgba(156, 39, 176, 0.14), 0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12)",
      "&:hover,&:focus": {
        backgroundColor: theme.palette.primary.dark,
        boxShadow:
          "0 14px 26px -12px rgba(156, 39, 176, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(156, 39, 176, 0.2)"
      },
    }
  },
  info: {
    "&.MuiButton-root": {
      backgroundColor: theme.palette.info.main,
      boxShadow:
      "0 2px 2px 0 rgba(0, 188, 212, 0.14), 0 3px 1px -2px rgba(0, 188, 212, 0.2), 0 1px 5px 0 rgba(0, 188, 212, 0.12)",
      "&:hover,&:focus": {
        backgroundColor: theme.palette.info.dark,
        boxShadow:
        "0 14px 26px -12px rgba(0, 188, 212, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 188, 212, 0.2)"
      },
    }
  },
  success: {
    "&.MuiButton-root": {
      backgroundColor: theme.palette.success.main,
      boxShadow:
      "0 2px 2px 0 rgba(76, 175, 80, 0.14), 0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12)",
      "&:hover,&:focus": {
        backgroundColor: theme.palette.success.dark,
        boxShadow:
        "0 14px 26px -12px rgba(76, 175, 80, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(76, 175, 80, 0.2)"
      },
    }
  },
  warning: {
    "&.MuiButton-root": {
      backgroundColor: theme.palette.warning.main,
      boxShadow:
      "0 2px 2px 0 rgba(255, 152, 0, 0.14), 0 3px 1px -2px rgba(255, 152, 0, 0.2), 0 1px 5px 0 rgba(255, 152, 0, 0.12)",
      "&:hover,&:focus": {
        backgroundColor: theme.palette.warning.dark,
        boxShadow:
        "0 14px 26px -12px rgba(255, 152, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(255, 152, 0, 0.2)"
      },
    }
  },
  danger: {
    "&.MuiButton-root": {
      backgroundColor: theme.palette.danger.main,
      boxShadow:
      "0 2px 2px 0 rgba(244, 67, 54, 0.14), 0 3px 1px -2px rgba(244, 67, 54, 0.2), 0 1px 5px 0 rgba(244, 67, 54, 0.12)",
      "&:hover,&:focus": {
        backgroundColor: theme.palette.danger.dark,
        boxShadow:
        "0 14px 26px -12px rgba(244, 67, 54, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(244, 67, 54, 0.2)"
      },
    }
  },
  white: {
    "&.MuiButton-root": {
      "&,&:focus,&:hover,&:visited": {
        backgroundColor: "#FFFFFF",
        color: theme.palette.gray.main
      }
    }
  }, 
  simple: {
    "&,&:focus,&:hover,&:visited": {
      color: "#FFFFFF",
      background: "transparent",
      boxShadow: "none"
    },
    "&$primary": {
      "&,&:focus,&:hover,&:visited": {
        color: theme.palette.primary.main
      }
    },
    "&$info": {
      "&,&:focus,&:hover,&:visited": {
        color: theme.palette.info.main
      }
    },
    "&$success": {
      "&,&:focus,&:hover,&:visited": {
        color: theme.palette.success.main
      }
    },
    "&$warning": {
      "&,&:focus,&:hover,&:visited": {
        color: theme.palette.warning.main
      }
    },
    "&$danger": {
      "&,&:focus,&:hover,&:visited": {
        color: theme.palette.danger.main
      }
    }
  },
  transparent: {
    "&.MuiButton-root": {
      color: "#777",
      "&:focus,&:hover,&:visited": {
        color: "#000",
        background: "transparent",
        boxShadow: "none"
      }
    }
  },
  disabled: {
    opacity: "0.65",
    pointerEvents: "none"
  },
  lg: {
    padding: "1.125rem 2.25rem",
    fontSize: "0.875rem",
    lineHeight: "1.333333",
    borderRadius: "0.2rem"
  },
  sm: {
    padding: "0.40625rem 1.25rem",
    fontSize: "0.6875rem",
    lineHeight: "1.5",
    borderRadius: "0.2rem"
  },
  round: {
    "&.MuiButton-root": {
      borderRadius: "30px"
    }
  },
  block: {
    width: "100% !important"
  },
  link: {
    "&.MuiButton-root": {
      "&,&:hover,&:focus": {
        backgroundColor: "transparent",
        color: "#999999",
        boxShadow: "none"
      }
    }
  },
  justIcon: {
    "&.MuiButton-root": {
      paddingLeft: "12px",
      paddingRight: "12px",
      fontSize: "20px",
      height: "41px",
      minWidth: "41px",
      width: "41px",
      "& .fab,& .fas,& .far,& .fal,& svg,& .material-icons": {
        marginRight: "0px"
      }
    },
    "&$lg": {
      "&.MuiButton-root": {
        height: "57px",
        minWidth: "57px",
        width: "57px",
        lineHeight: "56px",
        "& .fab,& .fas,& .far,& .fal,& .material-icons": {
          fontSize: "32px",
          lineHeight: "56px"
        },
        "& svg": {
          width: "32px",
          height: "32px"
        }
      }
    },
    "&$sm": {
      "&.MuiButton-root": {
        height: "30px",
        minWidth: "30px",
        width: "30px",
        "& .fab,& .fas,& .far,& .fal,& .material-icons": {
          fontSize: "17px",
          lineHeight: "29px"
        },
        "& svg": {
          width: "17px",
          height: "17px"
        }
      }
    }
  }
}));