import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";

import Button from '../Button/Button';

import { Box, Typography, Paper, Dialog, DialogTitle, DialogActions, DialogContentText, DialogContent, Slide } from '@mui/material';
import { Done, CloseOutlined } from '@material-ui/icons';

import Draggable from "react-draggable";
import { Status } from "../../../ApiService";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function CustomDialog(props) {
  const {
    open,
    title,
    childNode,
    handleConfirm,
    handleCancel,
    type,
    actionButtonColor,
    showFullWidth
  } = props;

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => (handleCancel ? handleCancel() : {})}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth={showFullWidth === true ? "xl" : false}
    >
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        <Box>
            <Typography variant='h5' sx={{ fontSize: { xs: 12, sm: 15, md: 15 }, fontWeight: 'bold' }}>{title}</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        {/* <DialogContentText>{childNode}</DialogContentText> */}
        {childNode}
      </DialogContent>
      <DialogActions>
        {type !== Status.ERROR && handleConfirm && (
          <Button
            variant="contained"
            autoFocus
            color={actionButtonColor}
            size="sm"
            type="submit"
            onClick={handleConfirm}
            startIcon={<Done />}
          >
            Confirm
          </Button>
        )}
        {handleCancel && (
          <Button variant="contained" color="white" size="sm" onClick={handleCancel}
            startIcon={<CloseOutlined />}>
             Cancel
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

CustomDialog.defaultProps = {
  open: false,
  actionButtonColor: "primary",
  handleCancel: ()=>{}
};

CustomDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  childNode: PropTypes.node,
  handleConfirm: PropTypes.func,
  handleCancel: PropTypes.func.isRequired,
  type: PropTypes.string,
  actionButtonColor: PropTypes.string,
  showFullWidth: PropTypes.bool,
};
