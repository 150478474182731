import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TableIcons from '../../common/TableLogoComponent';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ApiService from '../../../ApiService';
import Divider from '@material-ui/core/Divider';
import MaterialTable from '../../../components/MaterialTable';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import { Alert } from '@mui/material';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import CustomTooltip from '../../../components/Custom/Tooltip/Tooltip';
import { Button as MTButton } from '@mui/material';

import AddMerchantUrlComponent from './AddMerchantUrlComponent';

const useStyles = theme => ({
	root: {
		display: 'flex',
	},
});
class MerchantComponent extends Component {
	constructor(props) {
		super(props)
		this.state = {
			merchants: [],	
			showDetails: false,
			selectedMerchant: null,
			failed: false,
			success: false,
			responsemessage: null,
			addMerchantUrlPanelVisible: false,
			addMerchantPanelVisible: false,
		}
		this.fetchAllMerchants = this.fetchAllMerchants.bind(this);

		this.showAddMerchantUrlPanel = this.showAddMerchantUrlPanel.bind(this);
		this.hideAddMerchantUrlPanel = this.hideAddMerchantUrlPanel.bind(this);

		this.showAddMerchantDialogue = this.showAddMerchantDialogue.bind(this);
		this.hideAddMerchantDialogue = this.hideAddMerchantDialogue.bind(this);

		this.reloadData = this.reloadData.bind(this);
		this.showDetailsPanel = this.showDetailsPanel.bind(this);
		this.hideDetailsPanel = this.hideDetailsPanel.bind(this);
	}

	componentDidMount() {
		this.reloadData();
	}
	
	reloadData(){
		this.fetchAllMerchants();
	}

	fetchAllMerchants(){
		ApiService.fetchAllMerchants().then((res) => {
			this.setState({merchants: res.data})
		});		
	}

	showAddMerchantUrlPanel(data) {
		this.setState({selectedMerchant: data[0], addMerchantUrlPanelVisible: true});
	}
	hideAddMerchantUrlPanel() {
		this.setState({addMerchantUrlPanelVisible: false});
	}	

	showAddMerchantDialogue() {
		this.setState({addMerchantPanelVisible: true});
	}
	hideAddMerchantDialogue() {
		this.setState({addMerchantPanelVisible: false, });
	} 

	openFile(id, fileName) {
		/*const file = new Blob([doc.data],{type: 'image/*'});
		 
		var link = document.createElement('a');
		link.href = URL.createObjectURL(file);
		link.download = doc.fileName;
  		document.body.appendChild(link);
    	link.click();
    	document.body.removeChild(link);*/
		ApiService.downloadMerchantDoc(id, fileName);
	}

	showDetailsPanel(data){		
		ApiService.fetchMerchantDocuments(data[0].id).then((res) => {
			this.setState({documents: res.data})
		})
		this.setState({selectedMerchant: data[0], showDetails: true})
	}

	hideDetailsPanel(){
		this.setState({showDetails: false})
	}
	
	render() {
		const { classes } = this.props;
		const { selectedMerchant } = this.state;
		
		return (
				<Paper style={{margin: '1em', padding: '1em'}}>
					<Typography component="h2" variant="h6" color="primary" gutterBottom>Merchants</Typography>
					<Divider light className={classes.searchbar}/>
					{this.state.failed &&  <Alert severity="error" elevation="0"> {this.state.responsemessage}</Alert> }
					{this.state.success && <Alert severity="success" elevation="0">{this.state.responsemessage}</Alert>}
					<MaterialTable					  
						title="Merchants" 
						icons={TableIcons.icons}
						columns={[				       
						{ field: 'id', title: 'Id',render: rowData => 
							<CustomTooltip title={rowData.id} copy={true}><InfoOutlinedIcon/></CustomTooltip>
						},
						{ field: 'merchantName' , numeric: false, title: 'Name'},
						{ field: 'contactEmail' ,numeric: false, title: 'Email'},	 
						{ field: 'telephone' ,numeric: false, title: 'Phone'},
						{ field: 'orgRegistrationNumber' ,numeric: false, title: 'Reg No'},
						]}
						data={this.state.merchants}
						actions={[								 
						{
							tooltip: 'Show details',
							icon: (props)=> <MTButton variant="contained" color="primary" size="small">Details</MTButton>,
							onClick: (evt, data) => this.showDetailsPanel(data),
						},
						// {
						// 	icon: (props)=> <MTButton color="primary" size="sm"><AddIcon />Add Merchant</MTButton>,
						// 	tooltip: 'Add Merchant',
						// 	isFreeAction: true,
						// 	onClick: (event) => this.showAddMerchantDialogue(),
						// },
						{
							tooltip: 'Manage Merchant URL/IP',
							icon: (props)=> <MTButton variant="contained" color="primary" size="small">Merchant URL/IP</MTButton>,
							onClick: (evt, data) => this.showAddMerchantUrlPanel(data),
						},
						]}
						options={{
						search:false,
						exportButton: false,
						pageSize: 30,
						pageSizeOptions: [15,30,45],
						padding: 'dense',
						showEmptyDataSourceMessage: true,
						selection: true,
						emptyRowsWhenPaging: false,
						}}				     
					/>
					<Dialog open={this.state.showDetails} onClose={this.hideDetailsPanel}>
						<DialogTitle id="customized-dialog-title" onClose={this.hideDetailsPanel}>
			          		Merchant details
			        	</DialogTitle>
						<DialogContent>
							<TableContainer component={Paper}>
							{selectedMerchant &&
								<Table>
									<TableBody>
										<TableRow>
											<TableCell align="left">Merchant name</TableCell>
             								<TableCell align="left">{selectedMerchant.merchantName}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell align="left">Contact Email</TableCell>
             								<TableCell align="left">{selectedMerchant.contactEmail}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell align="left">Address</TableCell>
             								<TableCell align="left">{selectedMerchant.address},{selectedMerchant.city},{selectedMerchant.state},{selectedMerchant.country}, {selectedMerchant.zip}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell align="left">Telephone</TableCell>
             								<TableCell align="left">{selectedMerchant.telephone}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell align="left">Fax</TableCell>
             								<TableCell align="left">{selectedMerchant.fax}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell align="left">Registration number</TableCell>
             								<TableCell align="left">{selectedMerchant.orgRegistrationNumber}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell align="left">Industry</TableCell>
             								<TableCell align="left">{selectedMerchant.industry}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell align="left">Website</TableCell>
             								<TableCell align="left">{selectedMerchant.website}</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							 }
							</TableContainer>
							<Typography style={{padding: '1em'}}>Merchant Documents </Typography>
							<TableContainer component={Paper}>
								<Table>
									<TableBody>
										{this.state.documents && this.state.documents.map((doc) => 
											<TableRow>
												<TableCell align="left">{doc.fileName}</TableCell>
	             								<TableCell align="left">
													<DownloadIcon onClick={() => this.openFile(doc.id, doc.fileName)}/>
				 								</TableCell>
											</TableRow>
										)}
									</TableBody>
								</Table>
							</TableContainer>
						</DialogContent>
					</Dialog>

					{selectedMerchant &&
					<Dialog open={this.state.addMerchantUrlPanelVisible} onClose={this.hideAddMerchantUrlPanel}  maxWidth="md">
						<DialogTitle id="customized-dialog-url" onClose={this.hideAddMerchantUrlPanel}>
				          Add Merchant Front URL and IP
				        </DialogTitle>
						<DialogContent>
							<AddMerchantUrlComponent merchantId={selectedMerchant.id} 
							 	reloadMerchants={this.fetchAllMerchants} />
						</DialogContent>
					</Dialog>}

					{/* <Dialog open={this.state.addMerchantPanelVisible} onClose={this.hideAddMerchantDialogue}>
						<DialogTitle id="customized-dialog-title" onClose={this.hideAddMerchantDialogue}>
				          Add Merchant
				        </DialogTitle>
						<DialogContent>
							 <AddMerchant reloadMerchants={this.fetchAllMerchants}/>
						</DialogContent>
					</Dialog> */}
					
				</Paper>
			)
	}
}
export default withStyles(useStyles)(MerchantComponent)