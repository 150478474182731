import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { FileCopy } from '@material-ui/icons';

import { Button as MTButton } from '@mui/material';
export default function RegularTooltip(props) {
  const tProps = {...props, interactive: true, title: (props.copy && props.copy=== true) ? 
    <>
      <MTButton simple color="inherit" onClick={() => {navigator.clipboard.writeText(props.title)}}
        sx={{
          borderRadius: "30px",
          paddingLeft: "12px",
          paddingRight: "12px",
          fontSize: "20px",
          height: "41px",
          minWidth: "41px",
          width: "41px",
        }}>
        <FileCopy />
      </MTButton>{props.title}
    </>
    : props.title};
  return (<Tooltip TransitionComponent={Zoom} arrow {...tProps} />);
}

RegularTooltip.propTypes = {
  title: PropTypes.string,
    copy: PropTypes.bool
};
