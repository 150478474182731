import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {Divider, Typography, Paper, Grid,InputLabel, Select, MenuItem, Button, Box, FormControl, } from '@mui/material';
import ApiService from '../../ApiService';

import TableIcons from '../common/TableLogoComponent';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import { Alert } from '@mui/material';
import MaterialTable from '../../components/MaterialTable';

const useStyles = theme => ({
 
  searchbar: {
	margin: theme.spacing(2),
  },  
  formControl: {
    minWidth: 200,
  },

});

class UserAccessComponent extends Component {

	constructor(props) {
		 super(props);
	 	 this.state = {		
			userAccess: [],
			userMenus: [],
			actions: [],
			menus: [],
			menuToAdd: '',
			actionToAdd: [],
			failed: false,
			success: false, 
			responsemessage: null,
		}
		this.handleChange = this.handleChange.bind(this);
		this.reloadUserAccess = this.reloadUserAccess.bind(this);
		this.loadActions = this.loadActions.bind(this);
		this.loadMenu = this.loadMenu.bind(this);
		this.addUserMenu = this.addUserMenu.bind(this);
	}
	
	componentDidMount() { 
		this.reloadUserAccess();
		this.loadActions();
		this.loadMenu();
	}
	handleChange(event) {
        this.setState(
            {
                [event.target.name]
                    : event.target.value, critChanged: true,
            }
        )
    }
	reloadUserAccess() {
		ApiService.userMenuMappings(this.props.user[0].id).then((res) => {
			this.setState({userAccess: res.data})
		})
	}
	 
	
	loadMenu() {
		ApiService.fetchAllMenu().then((res) => {
			this.setState({menus: res.data})
		})
	}
	
	loadActions() {
		ApiService.getUserActions().then((res) => {
			this.setState({actions: res.data})
		});
	}
	
	addUserMenu() {
		//alert(this.props.user[0].id + " : " + this.state.menuToAdd + " : " + this.state.actionToAdd)
		ApiService.addUserMenuAccess(this.props.user[0].id, this.state.menuToAdd, this.state.actionToAdd).then((res) => {
			if(res.data.status == 'OK') {
				this.reloadUserAccess();
				this.setState({failed: false, success: true, responseMessage: res.responseMessage});
			} else {
				this.setState({failed: true, success: false,responseMessage: res.responseMessage})
			}
			
		}).catch((err) => {
			this.setState({failed: true, success: false,responseMessage: err})
		})
	}
	
	removeUserMenuMapping(data) {
		
		ApiService.removeUserMenuAccess(data.userId, data.menuId).then((res) => {
			if(res.data.status == 'OK') {
				this.reloadUserAccess();
				this.setState({failed: false, success: true, responseMessage: res.responseMessage});
			} else {
				this.setState({failed: true, success: false,responseMessage: res.responseMessage})
			}
		})
	}
	ITEM_HEIGHT = 50;
	ITEM_PADDING_TOP = 8;
 	 TerminalMenuProps = {
	  PaperProps: {
	    style: {
	      maxHeight: this.ITEM_HEIGHT * 5.5 + this.ITEM_PADDING_TOP,
		  overflowX: 'auto',
	    },
	  },
	};
 	 
    render() {	
		 const { classes } = this.props; 		
        return (             
            <>
				 
					{this.state.failed &&  <Alert severity="error" elevation="0"> {this.state.responsemessage}</Alert> }
					{this.state.success && <Alert severity="success" elevation="0">{this.state.responsemessage}</Alert>}
				
				<Grid xs={12} container>
					<Grid xs={8} item sx={{marginRight: "5px"}}>
					<FormControl className={classes.formControl} fullWidth>
						<InputLabel id="menuSelectLabel">Menu</InputLabel>
						  <Select value={this.state.menuToAdd}   MenuProps={this.TerminalMenuProps}
						  		onChange={this.handleChange} name="menuToAdd" label="Menu"
						  		labelId="menuSelectLabel" id="menuToAdd"
		 					>
							{this.state.menus && this.state.menus.map((m) => 
								<MenuItem key={m.id} value={m.id} dense>{m.feature.module + " : " + m.feature.feature + " : " + m.text}</MenuItem>
							)}
						</Select>
						</FormControl>
					</Grid>	
					<Grid xs={3} item>
					<FormControl className={classes.formControl} fullWidth >
						<InputLabel id="actionSelectLabel">Action</InputLabel>
						  <Select value={this.state.actionToAdd}  MenuProps={this.TerminalMenuProps}
						  		onChange={this.handleChange} name="actionToAdd" label="Action"
						  		labelId="actionSelectLabel" id="actionToAdd" multiple
		 					>
							{this.state.actions && this.state.actions.map((a) => 
								<MenuItem key={a} value={a} dense>{a}</MenuItem>
							)}
						</Select>
						</FormControl>
					</Grid>					
				</Grid>	
				<Grid xs={12} sx={{margin: "5px"}}>
				<Box align="center">
					<Button variant="contained" className={classes.searchbar}
				        color="primary"
				        size="small" onClick={this.addUserMenu} >Add </Button>
				</Box>
				</Grid>
					<MaterialTable
				      title="" 
					  icons={TableIcons.icons}
				      columns={[ 
				        { field: 'menu.feature.module',numeric: false, title: 'Module'},				
						{ field: 'menu.feature.feature' , numeric: false, title: 'Feature'},
						{ field: 'menu.text' ,numeric: false, title: 'Menu'},	 
						{ field: 'actions' ,numeric: false, title: 'Action', render: rowData =>  
							<Typography>{rowData.actions.map((a)=> 
								a.action
							)} </Typography>
						},	 
				      ]}
				      data={this.state.userAccess}
					  actions={[						
						{
							tooltip: 'Delete access',
							icon: (props)=> <DeleteIcon {...props} />,
							onClick: (evt, data) => this.removeUserMenuMapping(data),
						}
					  ]}
					  options={{
						search:false,
				        exportButton: false,
						pageSize: 10,
						pageSizeOptions: [10,20],
						padding: 'dense',
						showEmptyDataSourceMessage: true,
						emptyRowsWhenPaging: false,
				      }}				     
				    />
 		</>
        )
    }
}

export default withStyles(useStyles)(UserAccessComponent)
