import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  card: {
    border: "0",
    marginBottom: "30px",
    marginTop: "30px",
    borderRadius: "6px",
    color: "rgba(0, 0, 0, 0.95)",
    background: "#efefef",
    width: "100%",
    boxShadow:
      "0 5px 5px 0 rgba(0, 0, 0, 0.14), 0 6px 3px -2px rgba(0, 0, 0, 0.2), 0 6px 6px 0 rgba(0, 0, 0, 0.12)",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    minWidth: "0",
    wordWrap: "break-word",
    fontSize: ".875rem",
    transition: "all 300ms linear",
  },
  cardPlain: {
    background: "transparent",
    boxShadow: "none",
  },
  cardCarousel: {
    overflow: "hidden",
  },
  primary: { backgroundColor: theme.palette.primary.main },
  info: { backgroundColor: theme.palette.info.main },
  success: { backgroundColor: theme.palette.success.main },
  warning: { backgroundColor: theme.palette.warning.main },
  danger: { backgroundColor: theme.palette.danger.main },
  white: { backgroundColor: theme.palette.header.main },
  gray: { backgroundColor: theme.palette.gray.main },

  transparent: {
    "&,&:focus,&:hover,&:visited": {
      color: "inherit",
      background: "transparent"
    }
  }
}));
