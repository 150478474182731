import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import _ from 'lodash';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import HomeIcon from '@material-ui/icons/Home';
import ReceiptIcon from '@material-ui/icons/Receipt';
import MerchantIcon from '@material-ui/icons/SupervisedUserCircle';
//import RuleIcon from '@material-ui/icons/Rule';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import DashboardIcon from '@material-ui/icons/Dashboard';
import LayersIcon from '@material-ui/icons/SyncProblem';
import Avatar from '@material-ui/core/Avatar';
import ApiService from '../../ApiService';
 import ChbkIcon from '@material-ui/icons/SettingsBackupRestore';
import AppRegistrationIcon from '@material-ui/icons/HowToReg';

const Menu = [
	{ title: 'Home', role: 'HOME', icon: <HomeIcon /> },
	{ title: 'Onboarding', role: 'ONBOARDING', icon: <AppRegistrationIcon /> },
	{ title: 'Dashboard', role: 'DASHBOARD', icon: <DashboardIcon /> },
	{ title: 'Users', role: 'USERS', icon: <PeopleAltIcon /> },
	{ title: 'Acquirer Manage', role: 'ACQUIRER', icon: <AccountBalanceIcon />,
		items: [ 
			{ title: 'Acquirers', link: '/acquirerManagement' }, 
			{ title: 'Accounts', link: '/acquirerAccountManagement' }
		]
	},
	// { title: 'Affiliate', role: 'AFFILIATE', icon: <WorkIcon /> },
	{ title: 'Merchant Manage', role: 'MERCHANT', icon: <AppRegistrationIcon />,
		items: [
			{ title: 'Merchants', link: '/merchant' }, 
			{ title: 'Terminals', link: '/merchantTerminal' },
			{ title: 'Charges', link: '/merchantChargeback' } 
		]
	},
	{ title: 'Rule', role: 'RULE', icon: <MerchantIcon /> },
	{ title: 'Wire', role: 'WIRE', link: '/wireManagement', icon: <ReceiptIcon /> },
	{ title: 'Reports', role: 'WIRE', icon: <ReceiptIcon />,
		items: [
			{ title: 'Settlements', link: '/wireManagement' },
			{ title: 'Roll Reserve', link: '/rollingReserve' },
			{ title: 'Chargebacks', link: '/chargebackReport' }
		]
	},
	{ title: 'Transactions', role: 'TRANSACTIONS', 
		icon: <Avatar src="combo_chart.svg" variant="square" style={{width: 30, height: 30,}}/> },
	{ title: 'Txn Summary', role: 'SUMMARY', icon: <FormatListBulletedIcon /> },
	{ title: 'Txn Status', role: 'STATUSUPLOAD', icon: <LayersIcon /> },
	{ title: 'Card Whitelist', role: 'CARDWHITELIST', icon: <CreditCardIcon /> },
	{ title: 'Chargeback', role: 'CHARGEBACK', icon: <ChbkIcon /> },
];

class MenuComponent extends Component {

	constructor(props) {
		 super(props)
		 this.state = {
			menuItems : [],
			appMenuList: Menu,
			winWidth: 0,
			winHeight: 0
		}	 
	}
	
	componentDidMount() {
		window.addEventListener('resize', this.updateDimensions);
		this.updateDimensions();
		ApiService.fetchMenu().then((res) => {
			//alert("re.data" + res.data);
			this.setState({menuItems: res.data});
		});
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateDimensions);
	}

	updateDimensions = () => {
		this.setState({ winWidth: window.innerWidth, winHeight: window.innerHeight });
	};

	getAllMatchingMenu (menuList, role) {
		return _.filter(menuList,  function(c) { return c.role === role; });
	}	
 	
	renderMenuItem (mIdx, roleObj, menus, onClick) {
		if (roleObj === null || menus === null) return <></>;

		const menuItems = [];
		menus.map((menu, mmIdx) => {
			//console.log ("renderMenuItem-", roleObj, menu);
			const hasSubMenu = menu.items && menu.items.length > 0;
			const mKey = "M" + mIdx + "" + mmIdx;
			if (!hasSubMenu) {
				menuItems.push(<React.Fragment key={mKey}>
					<ListItemButton style={{ marginTop: "-5px"}} onClick={() => {
						onClick(); 
						this.props.history.push(roleObj.link);
					}}>
						<ListItemIcon>{menu.icon}</ListItemIcon>
						<ListItemText sx={{ marginLeft: "-10px" }} primary={menu.title} />
					</ListItemButton>
				</React.Fragment>);
			}
			else {
				// console.log ("menu.items ", menu.items); 
				menuItems.push(<React.Fragment key={mKey}>
					<ListItemButton style={{ marginTop: "-5px"}}
						onClick={() => {
							const menuIndex = _.findIndex(this.state.appMenuList, {title: menu.title, role: menu.role});						
							if (menuIndex > -1)	{
								const newMenuList = [...this.state.appMenuList];
								newMenuList[menuIndex].open = !newMenuList[menuIndex].open;
								newMenuList.splice(menuIndex, 1, newMenuList[menuIndex]);							
								this.setState({appMenuList : newMenuList});
							}
						}}>
						<ListItemIcon>{menu.icon}</ListItemIcon>
						<ListItemText sx={{ marginLeft: "-10px" }} primary={menu.title} />
						{menu.open ? <ExpandLess /> : <ExpandMore />}
					</ListItemButton>
					
					<Collapse in={menu.open} timeout="auto" unmountOnExit>
					<List component="div" key={mKey + "S" + mIdx} disablePadding sx={{ listStyleType: 'disc', pl: 4 }}>
						{menu.items.map((sMenu, idx) => {
							//console.log ("sMenu -", sMenu);
							const key = mKey + "S" + mIdx + "" + idx;
							return <>
							{sMenu.icon 
							? 	<ListItemButton key={key} sx={{marginLeft: "35px"}} onClick={() => {
										onClick(); 
										this.props.history.push(sMenu.link);
									}}>
									<ListItemIcon>{sMenu.icon}</ListItemIcon>
									<ListItemText primary={sMenu.title} />
								</ListItemButton>
							:	<ListItemButton key={key} sx={{ marginLeft: "35px", padding: 0.5, display: 'list-item' }}
									onClick={() => {
										onClick();
										this.props.history.push(sMenu.link)
									}} >
									<ListItemText primary={sMenu.title} />
								</ListItemButton>}
							</>
							})
						}
						</List>
					</Collapse>
				</React.Fragment>);
			}
		});
		return menuItems;
	}
	
    render() {
	 	const { appMenuList, winHeight } = this.state;
		 const { menuWidth, onClick } = this.props;
		console.log ("menuWidth ===> ", menuWidth);

		return (<List style={{ maxHeight: winHeight-80, overflowX: 'hidden', 
					overflowY: 'auto', width: menuWidth }}>
				{ this.state.menuItems && this.state.menuItems.map((mi, idx) => {
					return this.renderMenuItem(idx, mi, this.getAllMatchingMenu (appMenuList, mi.feature.feature), onClick);
				})}
			</List>);
    }
}

export default (withRouter)(MenuComponent)