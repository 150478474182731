import React, { useState, Component } from 'react';
import { ThemeProvider} from '@material-ui/core/styles';

class AppTheme extends Component {
    constructor(props) {
        super(props);
	  	
	  	this.state = {
            module: null,
        }
    }

    componentDidMount() {
        import(`./${process.env.REACT_APP_CLIENT}`)
        .then(module => this.setState({ module: module.default }));
    }

    render() {
        const {module} = this.state;
        console.log('MODULE >> ', module);
        //console.log(process.env.REACT_APP_CLIENT, module);
        return module == null ? null : <ThemeProvider theme={module.default}>{this.props.children}</ThemeProvider>
    }    
}

export default AppTheme;