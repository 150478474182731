import React, { Component } from 'react';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';

import {
	Alert, Paper, Typography, Divider, Select, MenuItem, 
	InputLabel, FormControl, Dialog, DialogTitle, DialogContent,
	Table, TableBody, TableCell, TableHead, TableRow,
	Button as MTButton
 } from '@mui/material';
	
import ApiService from '../../../ApiService';
import AddTerminalForm from './AddTerminalForm';
import EditTerminalForm from './EditTerminalForm';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CustomTooltip from '../../../components/Custom/Tooltip/Tooltip';
import { forwardRef } from 'react';
import AddIcon from '@material-ui/icons/Add';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import EditIcon from '@material-ui/icons/Edit';
import GridContainer from '../../../components/Custom/Grid/GridContainer';
import GridItem from '../../../components/Custom/Grid/GridItem';

import ObjectUtil from '../../../util/objectUtil';
import MaterialTable from '../../../components/MaterialTable';

const useStyles = (theme) => ({
  button: {
    margin: theme.spacing(1),
  },

  detailhead: {
    fontSize: 12,
  },
  searchbar: {
    margin: theme.spacing(2),
  },
  formControl: {
    marginLeft: 20,
    minWidth: 120,
    maxWidth: 300,
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: 'absolute',
    marginTop: 8,
    marginLeft: -65,
  },
});


class MerchantTerminalComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      merchantId: null,
      selectedMerchant: null,
      selectedTerminal: null,
      failed: false,
      success: false,
      responsemessage: null,
      merchants: [],
      terminals: [],
      addTerminalVisible: false,
      loading: false,
      terminalPanelVisible: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.onMerchantChange = this.onMerchantChange.bind(this);

    this.reloadData = this.reloadData.bind(this);

    this.showTerminals = this.showTerminals.bind(this);
    this.showTerminalsById = this.showTerminalsById.bind(this);

    this.hideAddTerminal = this.hideAddTerminal.bind(this);
    this.showAddTerminalDialogue = this.showAddTerminalDialogue.bind(this);
    this.showEditLimitDialogue = this.showEditLimitDialogue.bind(this);
    this.fetchAllMerchants = this.fetchAllMerchants.bind(this);
    this.migrateUser = this.migrateUser.bind(this);
    this.afterTerminalChange = this.afterTerminalChange.bind(this);
    this.reloadData();
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  reloadData() {
    this.fetchAllMerchants();
  }

  onMerchantChange(event) {
    this.handleChange(event);
    this.showTerminalsById(event.target.value.id);
  }
  showAddTerminalDialogue() {
    this.setState({ addTerminalVisible: true });
  }

  showEditLimitDialogue(data) {
    this.setState({ selectedTerminal: data, terminalPanelVisible: true });
  }
  hideAddTerminal() {
    this.setState({ addTerminalVisible: false, terminalPanelVisible: false });
  }
  fetchAllMerchants() {
    ApiService.fetchAllMerchants().then((res) => {
      this.setState({ merchants: res.data });
    });
  }

  showTerminalsById(merchantId) {
    if (merchantId || merchantId !== null) {
      ApiService.fetchMerchantTerminalsByMerchantId(merchantId)
        .then((res) => {
          this.setState({ terminals: res.data, terminalPanelVisible: false });
        })
        .catch((e) => {});
    }
  }
  showTerminals() {
    this.showTerminalsById(this.state.selectedMerchant.id);
  }
  afterTerminalChange(success, message) {
    this.setState({ success: success, responsemessage: message });
    this.showTerminals();
  }

  migrateUser() {
    this.setState({ loading: true });
    ApiService.migrateUser(this.state.selectedMerchant.id).then((res) => {
      if (res.data.status == 'OK')
        this.setState({
          success: true,
          responsemessage: 'User Migrated successfully',
        });
      else
        this.setState({
          success: false,
          failed: true,
          responsemessage: res.data.responseMessage,
        });
    });
    this.setState({ loading: false });
  }
  tableIcons = {
    Add: forwardRef((props, ref) => <AddIcon {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  render() {
    const { classes } = this.props;
    const { merchants, selectedMerchant } = this.state;
    console.log('MERCHANT TERMINAL >> ', this.props);
    return (
      <React.Fragment>
        <Paper style={{ margin: '1em', padding: '1em' }}>
          <Typography component='h2' variant='h6' color='primary' gutterBottom>
            Terminal management
          </Typography>
          <Divider light className={classes.searchbar} />
          {this.state.failed && (
            <Alert severity='error' elevation='0'>
              {' '}
              {this.state.responsemessage}
            </Alert>
          )}
          {this.state.success && (
            <Alert severity='success' elevation='0'>
              {this.state.responsemessage}
            </Alert>
          )}
          &nbsp;
          <GridContainer>
            <GridItem xs={8} sm={8} md={4} lg={3}>
              <FormControl className={classes.formControl} sx={{minWidth: "250px"}}>
                <InputLabel id='merchantSelectLabel'>Merchants</InputLabel>
                <Select
                  value={selectedMerchant}
                  MenuProps={this.MenuProps}
                  onChange={this.onMerchantChange}
                  name='selectedMerchant'
                  labelId='merchantSelectLabel'
                  id='merchantTerminalSelect'
                >
                  <MenuItem key='' value=''>
                    &nbsp;{' '}
                  </MenuItem>
                  {merchants &&
                    ObjectUtil.SortArray(merchants, [
                      'merchantName',
                      'code',
                    ]).map((m) => (
                      <MenuItem key={m.id} value={m} dense>
                        {m.merchantName + '-' + m.code}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </GridItem>
            {/* <GridItem xs={4} sm={4} md={8} lg={9} style={{ marginTop: "10px" }}>
							<Button disabled={selectedMerchant === null || selectedMerchant === ''} 
								size="sm" color="primary" onClick={this.showTerminals}>Show Terminals</Button>
							{this.state.loading && <CircularProgress size={25} className={classes.buttonProgress} />}
						</GridItem> */}
            {selectedMerchant && (
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <Table size='small' aria-label='a dense table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Merchant id </TableCell>
                      <TableCell>Username</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Website</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key='2'>
                      <TableCell>{selectedMerchant.id} </TableCell>
                      <TableCell>{selectedMerchant.username}</TableCell>
                      <TableCell>{selectedMerchant.contactEmail}</TableCell>
                      <TableCell>{selectedMerchant.website}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </GridItem>
            )}
            {/*<Grid item xs={4}>
							 <Button
						        variant="contained"
						        color="primary"
						        size="small"
						        className={classes.button}
								onClick={this.migrateUser}
						      >
						        Migrate user
						    </Button>
		 					{this.state.loading && <CircularProgress size={25} className={classes.buttonProgress} />}
					    </Grid>*/}
          </GridContainer>
          
            <MaterialTable
              style={{ margin: '1em 0 0 0' }}
              title='Merchant Terminals'
              icons={this.tableIcons}
              columns={[
                {
                  field: 'terminalId',
                  title: 'Id',
                  render: (rowData) => (
                    <CustomTooltip title={rowData.terminalId} copy={true}>
                      <InfoOutlinedIcon />
                    </CustomTooltip>
                  ),
                },
                { field: 'acquirer.name', title: 'Acquirer' },
                {
                  field: 'acquirerAccount.paymentMode.mode',
                  title: 'Pay mode',
                },
                { field: 'acquirerAccount.cardType.type', title: 'Card type' },
                { field: 'currency', title: 'Currency' },
                { field: 'settlementCurrency', title: 'Settlement curr' },
                { field: 'accountPoolId', title: 'Pool' },
                { field: 'isActive', title: 'Active' },
              ]}
              data={this.state.terminals}
              actions={[
                {
                  icon: (props) => (
                    <MTButton
                      variant='contained'
                      disabled={
                        selectedMerchant === null || selectedMerchant === ''
                      }
                      color='primary'
                      size='small'
                      startIcon={<AddIcon />}
                    >
                      {' '}
                      Add Terminal
                    </MTButton>
                  ),
                  tooltip: 'Add Terminal',
                  isFreeAction: true,
                  onClick: (event) => this.showAddTerminalDialogue(),
                },
                {
                  icon: (props) => (
                    <MTButton
                      variant='contained'
                      color='primary'
                      size='small'
                      startIcon={<EditIcon />}
                    >
                      {' '}
                      Limits and Fraud manage
                    </MTButton>
                  ),
                  tooltip: 'Edit limits',
                  isFreeAction: false,
                  onClick: (event, rowdata) =>
                    this.showEditLimitDialogue(rowdata),
                },
              ]}
              options={{
                search: false,
                exportButton: false,
                pageSize: 15,
                pageSizeOptions: [15, 30, 45],
                padding: 'dense',
                showEmptyDataSourceMessage: true,
                selection: true,
                emptyRowsWhenPaging: false,
              }}
            />
          <Dialog
            open={this.state.addTerminalVisible}
            onClose={this.hideAddTerminal}
          >
            <DialogTitle
              id='customized-dialog-mapterminal'
              onClose={this.hideAddTerminal}
            >
              Add Terminal
            </DialogTitle>
            <DialogContent>
              <AddTerminalForm
                merchantId={
                  this.state.selectedMerchant && this.state.selectedMerchant.id
                }
                reloadTerminals={this.showTerminals}
              />
            </DialogContent>
          </Dialog>
          <Dialog
            open={this.state.terminalPanelVisible}
            onClose={this.hideAddTerminal}
          >
            <DialogTitle
              id='customized-dialog-mapterminal'
              onClose={this.hideAddTerminal}
            >
              Terminal Limits
            </DialogTitle>
            <DialogContent>
              <EditTerminalForm
                terminal={this.state.selectedTerminal}
                reloadTerminals={this.afterTerminalChange}
              />
            </DialogContent>
          </Dialog>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withStyles(useStyles)(
  MerchantTerminalComponent
);
