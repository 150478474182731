import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TableIcons from '../common/TableLogoComponent';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ApiService from '../../ApiService';
import Divider from '@material-ui/core/Divider';
import MaterialTable from '../../components/MaterialTable';
import EditIcon from '@material-ui/icons/Edit';
import { Alert } from '@mui/material';
import AddBox from '@material-ui/icons/AddBox';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import AddUserForm from './AddUserForm';
import UserAccessComponent from './UserAccessComponent';
import { Button as MTButton } from '@mui/material';

const useStyles = theme => ({
	root: {
		display: 'flex',
	},
});

class UserManagementComponent extends Component {
	constructor(props) {
		super(props)
		this.state = {
			users: [],
			selectedUser: null,
			addUsersPanelVisible: false,
			manageAccessPanelVisible: false,
		}
		this.handleChange = this.handleChange.bind(this);
		this.showAddUserPanel = this.showAddUserPanel.bind(this);
		this.hideAddUserPanel = this.hideAddUserPanel.bind(this);
		this.reloadData = this.reloadData.bind(this);
		this.fetchUsers = this.fetchUsers.bind(this);
		this.showManageAccessPanel = this.showManageAccessPanel.bind(this);
		this.hideManageAccessPanel = this.hideManageAccessPanel.bind(this);
	}
	
	handleChange(event) {
        this.setState(
            {
                [event.target.name]
                    : event.target.value
            }
        )
    }
	componentDidMount() {
		this.reloadData();
	}
	showAddUserPanel() {
		this.setState({addUsersPanelVisible: true})
	}
	showManageAccessPanel(event) {
		this.setState({selectedUser: event, manageAccessPanelVisible: true});
	}
	hideAddUserPanel() {
		this.setState({addUsersPanelVisible: false})
	}
	hideManageAccessPanel() {
		this.setState({manageAccessPanelVisible: false});
	}
	reloadData() {
		//this.hideAddUserPanel();
		this.fetchUsers();
	}
	fetchUsers() {
		ApiService.fetchUsers().then((res) => {
			this.setState({users: res.data});
		})
	}
	amendUser(event) {
		//alert(JSON.stringify(event));
	}
	tableIcon2 = <tableIcon/>
	render() {
		const { classes } = this.props;
		
		return (
			<>
				<Paper style={{margin: '1em', padding: '1em'}}>
					   <Typography component="h2" variant="h6" color="primary" gutterBottom>
					      User management
					   </Typography>
					<Divider light className={classes.searchbar}/>
					{this.state.failed &&  <Alert severity="error" elevation="0"> {this.state.responsemessage}</Alert> }
					{this.state.success && <Alert severity="success" elevation="0">{this.state.responsemessage}</Alert>}
					<MaterialTable
					  
				      title="Users" 
					  icons={TableIcons.icons}
				      columns={[ 
				        { field: 'id',numeric: true, title: 'Id'},				
						{ field: 'userName' , numeric: false, title: 'Name'},
						{ field: 'email' ,numeric: false, title: 'Email'},	 
						{ field: 'phone' ,numeric: false, title: 'Phone'},	 
				        { field: 'role.type' ,numeric: false, title: 'Type'},
				      ]}
				      data={this.state.users}
					  actions={[
						{
							icon: (props)=> <MTButton variant="contained" color="primary" size="sm" className={classes.button} startIcon={<AddBox />}>Add User</MTButton>,
							tooltip: 'Add User',
							isFreeAction: true,
							onClick: (event) => this.showAddUserPanel(),
						},
						{
							tooltip: 'Amend selected Account',
							icon: (props)=> <EditIcon {...props} />,
							onClick: (evt, data) => this.amendUser(data),
						},
						{
							tooltip: 'Manage Access',
							icon: (props)=> <MTButton variant="contained" color="primary" size="sm" className={classes.button}>Access</MTButton>,
							onClick: (evt, data) => this.showManageAccessPanel(data),
						}
					  ]}
					  options={{
						search:true,
				        exportButton: false,
						pageSize: 30,
						pageSizeOptions: [15,30,45],
						padding: 'dense',
						showEmptyDataSourceMessage: true,
						selection: true,
						emptyRowsWhenPaging: false,
				      }}				     
				    />

				  <Dialog open={this.state.addUsersPanelVisible} onClose={this.hideAddUserPanel}>
					<DialogTitle id="customized-dialog-title" onClose={this.hideAddUserPanel}>
			          Add User
			        </DialogTitle>
					<DialogContent>
						<AddUserForm  reloadData={this.reloadData}/>
					</DialogContent>
				</Dialog>
				
				<Dialog open={this.state.manageAccessPanelVisible} onClose={this.hideManageAccessPanel}>
					<DialogTitle id="customized-dialog-title" onClose={this.hideManageAccessPanel}>
			          Manage User Access
			        </DialogTitle>
					<DialogContent>
						<UserAccessComponent  reloadData={this.reloadData} user={this.state.selectedUser}/>
					</DialogContent>
				</Dialog>
				</Paper>
			</>
		)};
}
export default withStyles(useStyles)(UserManagementComponent)