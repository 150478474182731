import React, { Component } from 'react'
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { createTheme } from '@mui/material';
import MaterialTableComponent from 'material-table';

const useStyles = (theme) => ({});
  

class MaterialTable extends Component {
  render() {
    const {theme, ...prop} = this.props;
    console.log('MATERIAL TABLE THEME 1 >> ', theme)
    console.log('MATERIAL TABLE THEME 2 >> ', this.props)
    return (
        <MuiThemeProvider
            theme={createTheme({
                ...theme,
                ...{
                    palette: {
                        secondary: {
                            main: theme.palette.primary.main,
                            light: theme.palette.primary.main,
                            dark: '#fff',
                        },
                    },
                },
            })}
        >
            <MaterialTableComponent {...prop} />
        </MuiThemeProvider>
    )
  }
}

export default withStyles(useStyles, { withTheme: true })(
    MaterialTable
  );
