import React from 'react'
import axios from 'axios';
import AppContext from './context/AppContext';
import Button from './components/Custom/Button/Button';
import { Close } from '@material-ui/icons';

export const REACT_APP_CLIENT = process.env.REACT_APP_CLIENT;
const API_URL = process.env.REACT_APP_HOST_URL;
const USER_API_BASE_URL = API_URL + '/reports/';

export const USER_NAME_SESSION_ATTRIBUTE_NAME = 'authenticatedUser'
export const MERCHANT_SESSION_ATTRIBUTE_NAME = 'loggedInMerchant'

export const Status = {
    SUCCESS: "success",
    ERROR: "error",
    INFO: "primary",
    NONE: undefined,
    NOTIFY: "default",
    DANGER: "danger",
};

export const setToast = (showSnackbar, closeSnackbar) => {
    AppContext.toast = { showSnackbar, closeSnackbar};
}

export const showToast = (msgObj) => {
    if (AppContext.toast && AppContext.toast.showSnackbar) {
        AppContext.toast.showSnackbar(msgObj.title + " - " + msgObj.description, {
          variant: msgObj.type ? msgObj.type : "info",
          autoHideDuration: 3500,
          action: (key) => (<Button simple onClick={() => AppContext.toast.closeSnackbar(key)}><Close /></Button>),
          // preventDuplicate: true,
        });
    }
}

class ApiService {

    fetchMerchants() {
        return axios.get(USER_API_BASE_URL + 'merchants');
    }
	
    fetchMerchantById(merchantId) {
        return axios.get(USER_API_BASE_URL + 'merchant?merchantId=' + merchantId);
    }

	fetchMerchantByName(merchantName) {
        return axios.get(USER_API_BASE_URL + 'merchantByName?merchantName=' + merchantName);
    }

	fetchLoggedInMerchant() {
        return this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME);
    }

	
	fetchUserRoles() {
		return axios.post(API_URL + '/user/fetchAllRoles', {
			"merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
		});
	}
	fetchUsers() {
		return axios.post(API_URL + '/user/fetchAllUsers', {
			"merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
		});
	}
	
	registerLoggedInMerchant(merchant) {
		this.setInSessionStorage(MERCHANT_SESSION_ATTRIBUTE_NAME, merchant)
	}
	fetchMerchantTransactions(fromDate, toDate, statuses, orderNo, email, terminalId, criteriaTxnId, pageNumber, numberRowPerPage, allChbk) {
		return axios.post( API_URL + '/reports/merchantTransactions', {
          "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
		  "terminalId" : terminalId,
          "fromDate" : fromDate,
		  "toDate" : toDate,
		  "statuses": statuses,
		  "orderNo" : orderNo,
		  "email" : email,
		  "criteriaTxnId" : criteriaTxnId,
		  "pageNumber" : pageNumber,
		  "numberRowPerPage" : numberRowPerPage,
		  "allChbk" : allChbk,
      })
	}
	fetchChargebackTransactions(fromDate, toDate, terminalId, pageNumber, numberRowPerPage) {
		return axios.post( API_URL + '/reports/chbkTransactions', {
          "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
		  "terminalId" : terminalId,
          "fromDate" : fromDate,
		  "toDate" : toDate,
 		  "pageNumber" : pageNumber,
		  "numberRowPerPage" : numberRowPerPage,
		})
	}
	
	changeTransactionStatus(transactionId, targetStatus, gatewayResponseTrackerId, transactionInfo, signInfo, billingDescriptor){
		return axios.post( API_URL + '/reports/updateTransactionStatus', {
          "transactionId" : transactionId,
          "targetStatus" : targetStatus,
		  "gatewayResponseTrackerId" : gatewayResponseTrackerId,
		  "transactionInfo": transactionInfo,
		  "signInfo": signInfo,
		  "billingDescriptor": billingDescriptor,
      })
	}
	
	fetchMerchantTerminals(){
		return axios.post( API_URL + '/wire/fetchMerchantTerminals', {
          "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
        })
	}
	
	fetchMerchantTerminalsByMerchantId(merId) {
		return axios.post( API_URL + '/wire/fetchMerchantTerminalsById', {
		  "enitityId": merId,
          "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
        })
	}

	fetchFraudRulesByMerchantId(merId) {
		return axios.post( API_URL + '/wire/fetchFraudRulesById', {	
			"merchantId":merId,	  
        })
	}

	fetchFraudRules() {
		return axios.post( API_URL + '/wire/fetchFraudRules', {		  
        })
	}	

	addFraduRule(fraudName,merId,fraudValue) {
		return axios.post( API_URL + '/wire/addFraudRulesById', {		
		  "merchantId":merId,		
		  "fraudName":fraudName,
		  "fraudValue":fraudValue,
        })
	}
	
	fetchAcquirerAccounts(){
		return axios.post( API_URL + '/wire/fetchAcquirerAccounts', {
          "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
        })
	}

	fetchAcquirerAccountsWithPoolId(poolId){
		return axios.post( API_URL + '/wire/fetchAcquirerAccounts', {
		  "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
		  "accountPoolId" : poolId,
        })
	}
	
	fetchAcquirerAccountsByAcquirer(acquirerId){
		return axios.post( API_URL + '/admfns/fetchAcquirerAccountsByAcquirer', {
          "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
		  "acquirerId" : acquirerId,
        })
	}

	addNewAcquirer(acquirer, callback){
		return axios.post( API_URL + '/admfns/addNewAcquirer', {
			acquirer: acquirer,
          	merchantId : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
        });
	}
	

	fetchMasterCharges(){
		return axios.post( API_URL + '/wire/fetchMasterCharges', {
          "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
        })
	}
	fetchEntityChargeDetails(request) {
		return axios.post( API_URL + '/wire/fetchEntityChargeDetails', request)
	}
	fetchWires(request) {
		return axios.post( API_URL + '/wire/fetchWires', request)
	}
	copyTerminalCharges(fromTerminal, toTerminal, fromDate) {
		return axios.post( API_URL + '/wire/copyEntityCharges', {
				"fromTerminal" : fromTerminal,
				"toTerminal" : toTerminal,
				"fromDate": fromDate,
			})
	}
	createWire(request) {
		return axios.post( API_URL + '/wire/createWire', request)
	}
	fetchExchangeRatesForWire(terminalId, accountId) {
		return axios.post( API_URL + '/wire/fetchExchangeRatesForWire', {
          "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
		  "enitityId": terminalId!=null?terminalId:accountId,
		  "entityType" : terminalId!=null?"MERCHANT":"ACQUIRER",
      })
	}
	addWireCharge(fromDate, toDate, terminalId, accountId, chargeMasterId, chargeValue, chargeDescription, chargeCurrency, arbitrary) {
		return axios.post( API_URL + '/wire/addWireCharge', {
          "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
 		  "fromDate" : fromDate,
		  "toDate" : toDate,
		  "enitityId": terminalId!=null?terminalId:accountId,
		  "entityType" : terminalId!=null?"MERCHANT":"ACQUIRER",
		  "chargeMasterId" : chargeMasterId,
		  "chargeValue" : chargeValue,
		  "chargeDescription" : chargeDescription,
		  "chargeCurrency" : chargeCurrency,
		  "arbitraryCharge" : arbitrary? "N" : "Y",	
      })
	}
	deleteWireCharge(wireChargeId) {
		return axios.post( API_URL + '/wire/deleteWireCharge', {
          "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
		  "wireChargeId":wireChargeId, 
        })
	}
	deleteWire(wireId, terminalId, accountId) {
		return axios.post( API_URL + '/wire/deleteWire', {
          "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
		  "wireId": wireId,
		  "enitityId":wireId,
		  "entityType" : terminalId!=null?"MERCHANT":"ACQUIRER",
        })
	}
	approveWire(wireId, terminalId, accountId) {
		return axios.post( API_URL + '/wire/approveWire', {
          "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
		  "wireId":wireId,
		  "enitityId":wireId,
		  "entityType" : terminalId!=null?"MERCHANT":"ACQUIRER",
        })
	}
	uploadTxnStatus(formData) {
		formData.append('merchantId', this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME));
		return axios.post( API_URL + '/wire/uploadTxnStatus', formData)
	}
	
	fetchEarliestRRSettleDate(terminalId){
		return axios.post(API_URL + '/wire/fetchEarliestRRSettleDate', {
			 "enitityId" : terminalId,
		})
	}
	updateRRStatus(wireId) {
		return axios.post(API_URL + '/wire/updateRRStatus', {
			 "wireId" : wireId,
		})
	}
	fetchWiresForRR(request){
		return axios.post( API_URL + '/wire/fetchWiresForRR', request);
	}
	
	changePassword(currentPass, newPass, confirmPass, passType) {
		return axios.post( API_URL + '/reports/changePassword', {
			"merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
			"currentPass" : currentPass,
			"newPass" : newPass,
			"confirmPass" : confirmPass,
			"passType": passType,
		})
	}
	fetchWirePdf(wireId, filePostfix) {
		axios({
			method: 'post',
			url: API_URL + '/wire/fetchWirePdf',
			responseType: 'blob',
			data: { 
				 "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
		  		 "wireId":wireId,
			}
		})
		.then(response => {
		//Create a Blob from the PDF Stream
		    const file = new Blob(
		      [response.data], 
		      {type: 'application/pdf'});
		var link = document.createElement('a');
		link.href = URL.createObjectURL(file);
		link.download = 'SettlementReport_'+wireId+'_'+filePostfix+'.pdf';
  		document.body.appendChild(link);
    	link.click();
    	document.body.removeChild(link);
		})
		.catch(error => {
		    console.log(error);
		}); 
	}
	
	fetchWireXls(wireId, filePostfix) {
		axios({
			method: 'post',
			url: API_URL + '/wire/fetchWireXls',
			responseType: 'blob',
			data: { 
				 "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
		  		 "wireId":wireId,
			}
		})
		.then(response => {
		//Create a Blob from the PDF Stream
		    const file = new Blob(
		      [response.data], 
		      {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
		//Build a URL from the file
		var link = document.createElement('a');
		link.href = URL.createObjectURL(file);
		link.download = 'transactions_'+wireId+'_'+filePostfix+'.xlsx';
  		document.body.appendChild(link);
    	link.click();
    	document.body.removeChild(link);

		//Open the URL on new Windowupl
		 //   window.open(fileURL);
		})
		.catch(error => {
		    console.log(error);
		}); 
		
	}
	
	getFromSessionStorate(key) {
		return sessionStorage.getItem(key);
	}
	setInSessionStorage(key, value) {
		sessionStorage.setItem(key, value);	
	}
	removeFromSessionStorage(key) {
		sessionStorage.removeItem(key);
	}
    executeJwtAuthenticationService(username, password) {
      return axios.post( API_URL + '/txn/AuthToken' , {
          "username" : username,
          "secret" : password,
		  "source" : "dashboard"
      })
  }
  fetchAllMerchants() {
        return axios.post(API_URL + '/admfns/fetchMerchants', {
			"merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
		});
    }
	fetchAllCardType() {
		return axios.post(API_URL + '/admfns/fetchAllCardType', {
			"merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
		});
	}
	fetchAllPaymentMode() {
		return axios.post(API_URL + '/admfns/fetchAllPaymentMode', {
			"merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
		});
	}
	fetchAllAcquirers() {
			return axios.post( API_URL + '/admfns/fetchAllAcquirers', {
          "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
        })
	}
	uploadCardWhitelist(formData) {
		formData.append('merchantId', this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME));
		return axios.post( API_URL + '/admfns/uploadCardWhitelist', formData)
	}
	addMerchant(formData) {		
		return axios.post( API_URL + '/admfns/addMerchant', {
			"merchant": formData,
			"loggedInUser": this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
		})
	}
	addTerminal(formData) {		
		return axios.post( API_URL + '/admfns/addTerminal', {
			"terminal": formData,
			"loggedInUser": this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
		})
	}
	editTerminal(formData) {		
		return axios.post( API_URL + '/admfns/editTerminal', {
			"terminal": formData,
			"loggedInUser": this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
		})
	}
	addAmendAcquirerAccount(formData) {		
		return axios.post( API_URL + '/admfns/addAmendAcquirerAccount', {
			"acquirerAccount": formData,
			"merchantId": this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
		})
	}
	fetchAllStatusUploadConfigs() {
		return axios.post(API_URL+'/admfns/fetchAllStatusUploadConfigs', {
          "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
        })
	}
	
	fetchTxnStatusSummary() {
		return axios.post(API_URL+'/admfns/fetchTxnStatusSummary', {
          "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
        })
	}
	
	fetchMonthsTxnSummary() {
		return axios.post(API_URL+'/admfns/fetchMonthsTxnSummary', {
          "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
        })
	}
	
	fetchMenu() {
		return axios.post(API_URL+'/admfns/adminMenu', {
          "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
        })
	}
	
	fetchAllMenu() {
		return axios.post(API_URL+'/admfns/allMenu', {
          "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
        })
	}
	
	getUserActions() {
		return axios.post(API_URL + '/admfns/userActionsEnum', {
			 "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
		})
	}
	
	userMenuMappings(userId) {
		return axios.post(API_URL + '/admfns/userMenuMappings', {
			 "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
			 "userId" : userId,
		})
	}
	
	addUserMenuAccess(userId, menuId, actions) {
		return axios.post(API_URL + '/admfns/addUserMenuAccess', {
			 "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
			 "userId" : userId,
			 "menuId" : menuId,
			 "actions" : actions,
		})
	}
	removeUserMenuAccess(userId, menuId) {
		return axios.post(API_URL + '/admfns/removeUserMenuAccess', {
			 "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
			 "userId" : userId,
			 "menuId" : menuId,
		})
	}
	
	checkAccessByMenuAndAction(menuText, action) { 
		return axios.post(API_URL + '/admfns/checkAccessByMenuAndAction', {
			 "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
			 "menuText" : menuText,
			 "action" : action,
		})
	}
	
	verifyAndCorrectTransactionStatus(txnId) {		
		return axios.post(API_URL + '/admfns/verifyAndCorrectTransactionStatus', {
			 "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
			 "txn_id" : txnId,
		})
	}
	addMerchantUrl(merchantId, url) {
		return axios.post(API_URL + '/admfns/addMerchantUrl', {
			 "merchant" : merchantId,
			 "url" : url,
		})
	}
	addMerchantIp(merchantId, ip) {
		return axios.post(API_URL + '/admfns/addMerchantIp', {
			 "merchant" : merchantId,
			 "ip" : ip,
		})
	}
	fetchMerchantUrls(merchantId){
		return axios.post(API_URL + '/admfns/fetchMerchantUrls', {
			 "merchant" : merchantId,			
		})
	}
	fetchMerchantIps(merchantId){
		return axios.post(API_URL + '/admfns/fetchMerchantIps', {
			 "merchant" : merchantId,			
		})
	}
	
	removeMerchantUrl(merchantId, id) {
		return axios.post(API_URL + '/admfns/removeMerchantUrl', {
			 "merchant" : merchantId,
			 "id" : id,
		})
	}
	removeMerchantIp(merchantId, id) {
		return axios.post(API_URL + '/admfns/removeMerchantIp', {
			 "merchant" : merchantId,
			 "id" : id,
		})
	}
	
	fetchOnboardingMerchants() {
		return axios.get(API_URL+ '/admfns/fetchOnboardingMerchants')
	}
	
	fetchMerchantDocuments(merchantId) {
		return axios.get(API_URL+ '/admfns/fetchMerchantDocuments?merchantUUID='+merchantId)
	}
	
    registerSuccessfulLogin(username, password) {
        this.setInSessionStorage(USER_NAME_SESSION_ATTRIBUTE_NAME, username);		
        this.setupAxiosInterceptors(this.createBasicAuthToken(username, password));
    }

    registerSuccessfulLoginForJwt(username, token) {
        this.setInSessionStorage(USER_NAME_SESSION_ATTRIBUTE_NAME, username)
        this.setupAxiosInterceptors(this.createJWTToken(token))
    }

    createJWTToken(token) {
        return token
    }
	

    logout() {
        this.removeFromSessionStorage(USER_NAME_SESSION_ATTRIBUTE_NAME);
		this.removeFromSessionStorage(MERCHANT_SESSION_ATTRIBUTE_NAME);
    }

    isUserLoggedIn() {
        let user = this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME)
        if (user === null) return false
        return true
    }

    getLoggedInUserName() {
        let user = this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME)
        if (user === null) return ''
        return user
    }

    setupAxiosInterceptors(token) {
        axios.interceptors.request.use(
            (config) => {
                if (this.isUserLoggedIn()) {
                    config.headers.authorization = token
                }
                return config
            }
        )
    }
downloadMerchantDoc(docId, docName) {
		axios({
				method: 'post',
				url: API_URL + '/admfns/merchantDocumentData',
				responseType: 'blob',
				data: {
					"id" : docId, 
					"fileName" : docName,
				}
				 
			})
			.then(response => {
				const file = new Blob([response.data]);
				//Build a URL from the file
			    const fileURL = URL.createObjectURL(file);
				var link = document.createElement('a');
				link.href = URL.createObjectURL(file);
				link.download = docName;
		  		document.body.appendChild(link);
		    	link.click();
		    	document.body.removeChild(link);
			})
		
	}
	approveMerchant(merchantId) {
		return axios.post(API_URL + '/admfns/approveMerchant',
			{
				"merchantId" : merchantId
			});
	}

	fetchTransactionsXL(fromDate, toDate, statuses, orderNo, email, terminalId, allChbk) {
		axios({
			method: 'post',
			url: API_URL + '/reports/fetchTransactionXL',
			responseType: 'blob',
			data: { 
				 "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
		  		 "fromDate" : fromDate,
		  		 "toDate" : toDate,
		  		 "statuses": statuses,
	 		  	 "orderNo" : orderNo,
				 "email" : email,
				 "terminalId": terminalId,
				 "allChbk" : allChbk
			}
		})
		.then(response => {
		 const file = new Blob(
	      [response.data], 
	      {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
		var link = document.createElement('a');
		link.href = URL.createObjectURL(file);
		link.download = 'transactions_'+fromDate+'_'+toDate+'.xlsx';
  		document.body.appendChild(link);
    	link.click();
    	document.body.removeChild(link);
		})
		.catch(error => {
		    console.log(error);
		}); 
	}
	fetchTransactionsCSV(fromDate, toDate, statuses, orderNo, email, terminalId, allChbk) {
		axios({
			method: 'post',
			url: API_URL + '/reports/fetchTransactionCSV',
			responseType: 'blob',
			data: { 
				 "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
		  		 "fromDate" : fromDate,
		  		 "toDate" : toDate,
		  		 "statuses": statuses,
	 		  	 "orderNo" : orderNo,
				 "email" : email,
				 "terminalId": terminalId,
				 "allChbk" : allChbk
			}
		})
		.then(response => {
		 const file = new Blob(
	      [response.data], 
	      {type: 'application/text'});
		var link = document.createElement('a');
		link.href = URL.createObjectURL(file);
		link.download = 'transactions_'+fromDate+'_'+toDate+'.csv';
  		document.body.appendChild(link);
    	link.click();
    	document.body.removeChild(link);
		})
		.catch(error => {
		    console.log(error);
		}); 
	}
	fetchMerchantTransactionSummary(fromDate,fromTime,toDate, toTime, 
				refundFromDate, refundFromTime, refundToDate, refundToTime, 
				chbkFromDate, chbkFromTime, chbkToDate, chbkToTime,
				terminalId) {
		 return axios.post( API_URL + '/reports/fetchMerchantTransactionSummary', {
			"merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
			"fromDate" : fromDate,
			"fromTime" : fromTime,
			"toDate" : toDate,
			"toTime": toTime,
			"refundFromDate" : refundFromDate,
			"refundFromTime" : refundFromTime,
			"refundToDate" : refundToDate,
			"refundToTime" : refundToTime,
			"chbkFromDate" : chbkFromDate,
			"chbkFromTime" : chbkFromTime,
			"chbkToDate" : chbkToDate,
			"chbkToTime" : chbkToTime,			
			"terminalId": terminalId,
		})
	}
	
	markDeclinedSettled(fromDate,fromTime,toDate, toTime, 
				refundFromDate, refundFromTime, refundToDate, refundToTime, 
				chbkFromDate, chbkFromTime, chbkToDate, chbkToTime,
				terminalId) {
		 return axios.post( API_URL + '/reports/markDeclinedSettled', {
			"merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
			"fromDate" : fromDate,
			"fromTime" : fromTime,
			"toDate" : toDate,
			"toTime": toTime,
			"refundFromDate" : refundFromDate,
			"refundFromTime" : refundFromTime,
			"refundToDate" : refundToDate,
			"refundToTime" : refundToTime,
			"chbkFromDate" : chbkFromDate,
			"chbkFromTime" : chbkFromTime,
			"chbkToDate" : chbkToDate,
			"chbkToTime" : chbkToTime,			
			"terminalId": terminalId,
		})
	}

	fetchMerchantTransactionSummaryByAcquirerAccount(fromDate,fromTime,toDate, toTime, 
			refundFromDate, refundFromTime, refundToDate, refundToTime, 
			chbkFromDate, chbkFromTime, chbkToDate, chbkToTime,
			acquirerAccountId, accountPoolId) {
		 return axios.post( API_URL + '/reports/fetchMerchantTransactionSummaryByAcquirerAccount', {
			"merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
			"fromDate" : fromDate,
			"fromTime" : fromTime,
			"toDate" : toDate,
			"toTime": toTime,
			"refundFromDate" : refundFromDate,
			"refundFromTime" : refundFromTime,
			"refundToDate" : refundToDate,
			"refundToTime" : refundToTime,
			"chbkFromDate" : chbkFromDate,
			"chbkFromTime" : chbkFromTime,
			"chbkToDate" : chbkToDate,
			"chbkToTime" : chbkToTime,
			"acquirerAccountId": acquirerAccountId,
			"accountPoolId": accountPoolId,
		})
	}
	
	markDeclinedSettledByAcquirer(fromDate,fromTime,toDate, toTime, 
			refundFromDate, refundFromTime, refundToDate, refundToTime, 
			chbkFromDate, chbkFromTime, chbkToDate, chbkToTime,
			acquirerAccountId, accountPoolId) {
		 return axios.post( API_URL + '/reports/markDeclinedSettledByAcquirer', {
			"merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
			"fromDate" : fromDate,
			"fromTime" : fromTime,
			"toDate" : toDate,
			"toTime": toTime,
			"refundFromDate" : refundFromDate,
			"refundFromTime" : refundFromTime,
			"refundToDate" : refundToDate,
			"refundToTime" : refundToTime,
			"chbkFromDate" : chbkFromDate,
			"chbkFromTime" : chbkFromTime,
			"chbkToDate" : chbkToDate,
			"chbkToTime" : chbkToTime,
			"acquirerAccountId": acquirerAccountId,
			"accountPoolId" : accountPoolId,
		})
	}
	
	markSuccessSettledByAcquirer(fromDate,fromTime,toDate, toTime, 
			refundFromDate, refundFromTime, refundToDate, refundToTime, 
			chbkFromDate, chbkFromTime, chbkToDate, chbkToTime,
			acquirerAccountId, accountPoolId) {
		 return axios.post( API_URL + '/reports/markSuccessSettledByAcquirer', {
			"merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
			"fromDate" : fromDate,
			"fromTime" : fromTime,
			"toDate" : toDate,
			"toTime": toTime,
			"refundFromDate" : refundFromDate,
			"refundFromTime" : refundFromTime,
			"refundToDate" : refundToDate,
			"refundToTime" : refundToTime,
			"chbkFromDate" : chbkFromDate,
			"chbkFromTime" : chbkFromTime,
			"chbkToDate" : chbkToDate,
			"chbkToTime" : chbkToTime,
			"acquirerAccountId": acquirerAccountId,
			"accountPoolId" : accountPoolId,
		})
	}
	markRRSettledByAcquirer(fromDate,fromTime,toDate, toTime, 
			refundFromDate, refundFromTime, refundToDate, refundToTime, 
			chbkFromDate, chbkFromTime, chbkToDate, chbkToTime,
			acquirerAccountId, accountPoolId) {
		 return axios.post( API_URL + '/reports/markRRSettledByAcquirer', {
			"merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
			"fromDate" : fromDate,
			"fromTime" : fromTime,
			"toDate" : toDate,
			"toTime": toTime,
			"refundFromDate" : refundFromDate,
			"refundFromTime" : refundFromTime,
			"refundToDate" : refundToDate,
			"refundToTime" : refundToTime,
			"chbkFromDate" : chbkFromDate,
			"chbkFromTime" : chbkFromTime,
			"chbkToDate" : chbkToDate,
			"chbkToTime" : chbkToTime,
			"acquirerAccountId": acquirerAccountId,
			"accountPoolId" : accountPoolId,
		})
	}
	
	reconTransactions(fromDate,fromTime,toDate, toTime, acquirerAccountId) {
		 return axios.post( API_URL + '/admfns/verifyAndCorrectByAcquirerAccount', {
				"merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
				"fromDate" : fromDate,
				"fromTime" : fromTime,
				"toDate" : toDate,
				"toTime": toTime,
				"acquirerAccountIds": acquirerAccountId,
			})
	}
	
	refundMerchantTransaction(transactionId, reason, refundAmount) {
		 return axios.post( API_URL + '/reports/refundMerchantTransaction', {
			"merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
			"merchantTransactionId" : transactionId,
			"reason" : reason,
			"refundAmount" : refundAmount,			
		})
	}
	
	addAmendUser(request) {
		 return axios.post( API_URL + '/user/addUser', {
			"loggerInUser" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
			"merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
			"request" : request,			
		})
	}
	migrateUser(migrationMerchantId) {
		 return axios.get(API_URL + '/user/migrateUser?migrationMerchantId=' + migrationMerchantId + '&merchantId=' + this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME)); 
		
	}
}

export default new ApiService();

// 