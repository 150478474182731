import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";


import {
	Avatar, Container, Card, CardContent, CardHeader,
	Box, Backdrop, Divider, Paper, Alert, Checkbox, Select, MenuItem, 
	Input, InputLabel, FormControl, FormControlLabel, Grid, CircularProgress,
	Table, TableBody, TableCell, TableHead, TableRow,
	Dialog, DialogTitle, DialogContent, TextField,
	Typography, Button as MTButton
 } from '@mui/material';

import ApiService from "../../ApiService";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import MaterialTable from "../../components/MaterialTable";
import DateFnsUtils from "@date-io/date-fns";
import { subMonths, subDays, format } from "date-fns";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Button from "../../components/Custom/Button/Button";
import ChargeForm from "../../EntityChargeConfigForm";
import CloseIcon from "@material-ui/icons/Close";
import FileCopyIcon from "@material-ui/icons/FileCopy";

import {
  MuiPickersUtilsProvider,
  DatePicker,
  DateTimePicker,
  TimePicker,
} from "@material-ui/pickers";
import GridContainer from "../../components/Custom/Grid/GridContainer";
import GridItem from "../../components/Custom/Grid/GridItem";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

const useStyles = (theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  detailhead: {
    fontSize: 12,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  searchbar: {
    margin: theme.spacing(2),
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: "absolute",
    marginTop: 8,
    marginLeft: -65,
  },
});

class WireManagementComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      merchantId: null,
      fromDate: null,
      toDate: null,
      declinedFrom: null,
      declinedTo: null,
      chbkFrom: null,
      chbkTo: format(new Date(), "yyyy-MM-dd"),
      refundFrom: null,
      refundTo: format(new Date(), "yyyy-MM-dd"),
      fromDateObj: null,
      toDateObj: null,
      declinedFromObj: null,
      declinedToObj: null,
      chbkFromObj: null,
      chbkToObj: new Date().setHours(0, 0, 0),
      refundFromObj: null,
      refundToObj: new Date().setHours(0, 0, 0),
      releaseFrom: null,
      releaseTo: null,
      loading: false,
      showCharges: false,
      failed: false,
      addChargePanelVisible: false,
      copyChargePanelVisible: false,
      terminalId: null,
      acquirerAccountId: null,
      acquirerAccounts: [],
      merchantTerminals: [],
      masterCharges: [],
      entityChargeConfigs: [],
      wires: [],
      responsemessage: null,
      exchangeRates: [],
      exchangeRatePanelVisible: false,
      progressOpen: false,
      previousBalance: "0.00",
      copyTerminalId: null,
      success: false,
      fromTime: new Date().setHours(0, 0, 0),
      toTime: new Date().setHours(23, 59, 59),
      declinedFromTime: new Date().setHours(0, 0, 0),
      declinedToTime: new Date().setHours(23, 59, 59),
      chbkFromTime: new Date().setHours(0, 0, 0),
      chbkToTime: new Date().setHours(23, 59, 59),
      refundFromTime: new Date().setHours(0, 0, 0),
      refundToTime: new Date().setHours(23, 59, 59),
      fromTimeStr: "00:00:00",
      toTimeStr: "23:59:59",
      declinedFromTimeStr: "00:00:00",
      declinedToTimeStr: "23:59:59",
      chbkFromTimeStr: "00:00:00",
      chbkToTimeStr: "23:59:59",
      refundFromTimeStr: "00:00:00",
      refundToTimeStr: "23:59:59",
      entityType: "MERCHANT",
    };
    this.reloadData = this.reloadData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.showAddChargePanel = this.showAddChargePanel.bind(this);
    this.hideAddChargePanel = this.hideAddChargePanel.bind(this);
    this.showCopyChargePanel = this.showCopyChargePanel.bind(this);
    this.hideCopyChargePanel = this.hideCopyChargePanel.bind(this);
    this.hideExchangeRatesPanel = this.hideExchangeRatesPanel.bind(this);
    this.fetchEntityMasterCharges = this.fetchEntityMasterCharges.bind(this);
    this.copyTerminalCharges = this.copyTerminalCharges.bind(this);
  }
  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  reloadData() {
    if (this.state.terminalId == null && this.state.acquirerAccountId == null) {
      this.setState({
        responsemessage:
          "Please select either of Merchant Terminal and Acquirer account",
        failed: true,
      });
      return;
    }
    this.setState({ failed: false, showCharges: true });
    //call api functions to show chareges and wires
    this.fetchEntityMasterCharges();
    this.fetchWires();
  }
  fetchMerchantTerminals() {
    ApiService.fetchMerchantTerminals()
      .then((res) => {
        this.setState({ merchantTerminals: res.data });
      })
      .catch((e) => {});
  }
  fetchAcquirerAccounts() {
    ApiService.fetchAcquirerAccounts()
      .then((res) => {
        this.setState({ acquirerAccounts: res.data });
      })
      .catch((e) => {});
  }
  fetchMasterCharges() {
    ApiService.fetchMasterCharges().then((res) => {
      this.setState({ masterCharges: res.data });
    });
  }
  fetchEntityMasterCharges() {
    this.setState({ enitityId: this.state.terminalId });
    this.state.enitityId = this.state.terminalId;
    var request = Object.assign({}, this.state);
    ApiService.fetchEntityChargeDetails(request).then((res) => {
      this.setState({ entityChargeConfigs: res.data });
    });
  }
  fetchWires() {
    this.setState({ enitityId: this.state.terminalId });
    this.state.enitityId = this.state.terminalId;
    var request = Object.assign({}, this.state);
    ApiService.fetchWires(request).then((res) => {
      this.setState({ wires: res.data });
    });
  }
  showAddChargePanel() {
    this.setState({ addChargePanelVisible: true });
  }
  hideAddChargePanel() {
    this.setState({ addChargePanelVisible: false });
  }
  showCopyChargePanel() {
    this.setState({ copyChargePanelVisible: true });
  }
  hideCopyChargePanel() {
    this.setState({ copyChargePanelVisible: false });
  }
  hideExchangeRatesPanel() {
    this.setState({ exchangeRatePanelVisible: false });
  }

  fetchExchangeRatesForWire() {
    ApiService.fetchExchangeRatesForWire(
      this.state.terminalId,
      this.state.acquirerAccountId
    ).then((res) => {
      if (res.data == undefined || res.data.length == 0) {
        this.generateWire();
      } else {
        this.setState({
          exchangeRates: res.data,
          exchangeRatePanelVisible: true,
        });
      }
    });
  }
  copyTerminalCharges() {
    ApiService.copyTerminalCharges(
      this.state.copyTerminalId,
      this.state.terminalId,
      this.state.fromDateObj
    ).then((res) => {
      if (res.data.status == "OK") {
        this.setState({
          responsemessage: res.data.responseMessage,
          success: true,
          failed: false,
          progressOpen: false,
        });
        this.fetchEntityMasterCharges();
      } else {
        this.setState({
          responsemessage: res.data.responseMessage,
          success: false,
          exchangeRatePanelVisible: false,
          failed: true,
          progressOpen: false,
        });
      }
    });
  }

  generateWire() {
    this.setState({ enitityId: this.state.terminalId, openProgress: true });
    this.state.enitityId = this.state.terminalId;
    var request = Object.assign({}, this.state);
    ApiService.createWire(request).then((res) => {
      if (res.data.status == "OK") {
        this.setState({
          exchangeRatePanelVisible: false,
          failed: false,
          progressOpen: false,
        });
        this.fetchWires();
      } else {
        this.setState({
          responsemessage: res.data.responseMessage,
          exchangeRatePanelVisible: false,
          failed: true,
          progressOpen: false,
        });
      }
    });
  }

  deleteEntityWireChargeConfig(data) {
    data.map((d) =>
      ApiService.deleteWireCharge(d.entityWireChargeId).then((res) => {
        this.fetchEntityMasterCharges();
      })
    );
  }
  deleteWire(wireId) {
    ApiService.deleteWire(
      wireId,
      this.state.terminalId,
      this.state.acquirerAccountId
    ).then((res) => {
      this.fetchWires();
    });
  }
  approveWire(wireId) {
    this.setState({ progressOpen: true });
    ApiService.approveWire(
      wireId,
      this.state.terminalId,
      this.state.acquirerAccountId
    ).then((res) => {
      this.fetchWires();
      this.setState({ progressOpen: false });
    });
  }
  showPdf(wireId) {
    ApiService.fetchWirePdf(
      wireId,
      format(this.state.fromDateObj, "yyyyMMdd") +
        "_" +
        format(this.state.toDateObj, "yyyyMMdd")
    );
  }

  showXLS(wireId) {
    ApiService.fetchWireXls(
      wireId,
      format(this.state.fromDateObj, "yyyyMMdd") +
        "_" +
        format(this.state.toDateObj, "yyyyMMdd")
    );
  }

  componentDidMount() {
    this.fetchMerchantTerminals();
    this.fetchAcquirerAccounts();
    this.fetchMasterCharges();
  }

  headCells2 = [
    { id: "ChargevName", numeric: false, label: "ChargevName" },
    { id: "Value", numeric: true, label: "Value" },
    { id: "Total", numeric: true, label: "Total" },
    { id: "Direction", numeric: false, label: "Direction" },
  ];

  MenuProps = {
    PaperProps: {
      style: {
        maxHeight: this.ITEM_HEIGHT * 4.5 + this.ITEM_PADDING_TOP,
        width: 380,
      },
    },
  };
  tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Paper style={{ margin: "1em", padding: "1em" }}>
          <Typography component="h2" variant="h6" color="primary" gutterBottom>
            Wire Management
          </Typography>
          <Divider light className={classes.searchbar} />
          {this.state.failed && (
            <Alert severity="error" elevation="0">
              {" "}
              {this.state.responsemessage}
            </Alert>
          )}
          {this.state.success && (
            <Alert severity="success" elevation="0">
              {" "}
              {this.state.responsemessage}
            </Alert>
          )}
          &nbsp;
          <GridContainer>
            <GridItem xs={12} sm={12} md={6} lg={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <GridContainer>
                  <GridItem xs={6} sm={6} md={3} lg={3}>
                    <DatePicker
                      autoOk
                      label="From"
                      format="dd MMM yyyy"
                      value={this.state.fromDateObj}
                      onChange={(val) =>
                        this.setState({
                          fromDateObj: val,
                          fromDate: format(val, "yyyy-MM-dd"),
                          declinedFromObj: val,
                          declinedFrom: format(val, "yyyy-MM-dd"),
                          chbkFromObj: subDays(val, 180),
                          chbkFrom: format(subDays(val, 180), "yyyy-MM-dd"),
                          refundFromObj: subDays(val, 180),
                          refundFrom: format(subDays(val, 180), "yyyy-MM-dd"),
                          releaseFrom: subDays(val, 180),
                        })
                      }
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={3} lg={3}>
                    <TimePicker
                      label="From Time"
                      views={["hours", "minutes", "seconds"]}
                      value={this.state.fromTime}
                      format="HH:mm:ss"
                      onChange={(val) =>
                        this.setState({
                          fromTime: val,
                          declinedFromTime: val,
                          fromTimeStr: format(val, "HH:mm:ss"),
                          declinedFromTimeStr: format(val, "HH:mm:ss"),
                        })
                      }
                    ></TimePicker>
                  </GridItem>
                  <GridItem xs={6} sm={6} md={3} lg={3}>
                    <DatePicker
                      autoOk
                      label="To"
                      format="dd MMM yyyy"
                      value={this.state.toDateObj}
                      onChange={(val) =>
                        this.setState({
                          toDateObj: val,
                          toDate: format(val, "yyyy-MM-dd"),
                          declinedToObj: val,
                          declinedTo: format(val, "yyyy-MM-dd"),
                          releaseTo: subDays(val, 180),
                        })
                      }
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={3} lg={3}>
                    <TimePicker
                      label="To Time"
                      views={["hours", "minutes", "seconds"]}
                      value={this.state.toTime}
                      format="HH:mm:ss"
                      onChange={(val) =>
                        this.setState({
                          toTime: val,
                          declinedToTime: val,
                          toTimeStr: format(val, "HH:mm:ss"),
                          declinedToTimeStr: format(val, "HH:mm:ss"),
                        })
                      }
                    ></TimePicker>
                  </GridItem>
                </GridContainer>
              </MuiPickersUtilsProvider>
            </GridItem>
            <GridItem xs={12} sm={12} md={2} lg={2}>
              <FormControl fullWidth>
                <InputLabel id="merchantTerminalSelectLabel">
                  Terminals
                </InputLabel>
                <Select
                  value={this.state.terminalId}
                  MenuProps={this.MenuProps}
                  onChange={this.handleChange}
                  name="terminalId"
                  labelId="merchantTerminalSelectLabel"
                  id="merchantTerminalSelect"
                >
                  <MenuItem key="" value="">
                    &nbsp;{" "}
                  </MenuItem>
                  {this.state.merchantTerminals &&
                    this.state.merchantTerminals.map((mt) => (
                      <MenuItem key={mt.terminalId} value={mt.terminalId} dense>
                        {mt.label + "-" + mt.terminalCardType}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12} md={1} lg={1}>
              <Typography variant="h6">OR</Typography>
            </GridItem>
            <GridItem xs={12} sm={12} md={2} lg={2}>
              <FormControl fullWidth>
                <InputLabel id="acquirerAccountSelectLabel">
                  Acquirer Acc
                </InputLabel>
                <Select
                  value={this.state.acquirerAccountId}
                  MenuProps={this.MenuProps}
                  onChange={this.handleChange}
                  name="acquirerAccountId"
                  labelId="acquirerAccountSelectLabel"
                  id="acquirerAccountSelect"
                >
                  <MenuItem key="" value="">
                    &nbsp;
                  </MenuItem>
                  {/*this.state.acquirerAccounts && this.state.acquirerAccounts.map((ac) =>
							  	 <MenuItem key={ac.acquirerAccountId} value={ac.acquirerAccountId} dense>{ac.userName +"-" 
										+ ac.mid + "-" + ac.settlementCurrency + "-" + ac.paymentMode.mode + "-" + ac.ardType.type}</MenuItem>
							)*/}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12} md={4} lg={3}>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.button}
                  onClick={this.reloadData}
                >
                  Go
                </Button>
                {this.state.loading && (
                  <CircularProgress
                    size={25}
                    className={classes.buttonProgress}
                  />
                )}
              </Grid>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              {this.state.showCharges && (
                <MaterialTable
                  style={{ margin: "1em 0 0 0" }}
                  title="Configured charges"
                  icons={this.tableIcons}
                  columns={[
                    { field: "chargeDescription", title: "Name" },
                    { field: "chargeValue", type: "datetime", title: "value" },
                    { field: "charge.type", title: "Type" },
                    { field: "charge.direction", title: "Direction" },
                    { field: "charge.frequency", title: "Frequency" },
                    {
                      field: "validFrom",
                      title: "From",
                      render: (rowData) => (
                        <Typography noWrap="true" variant="caption">
                          {format(new Date(rowData.validFrom), "dd MMM yyyy")}
                        </Typography>
                      ),
                    },
                    {
                      field: "validTo",
                      title: "To",
                      render: (rowData) => (
                        <Typography noWrap="true" variant="caption">
                          {format(new Date(rowData.validTo), "dd MMM yyyy")}
                        </Typography>
                      ),
                    },
                    { field: "chargeCurrency", title: "Currency" },
                  ]}
                  data={this.state.entityChargeConfigs}
                  actions={[
                    {
                      icon: (props) => (
                        <MTButton
                          variant="contained"
                          color="primary"
                          size="small"
                          className={classes.button}
                          startIcon={<AddBox />}
                        >
                          Wire
                        </MTButton>
                      ),
                      tooltip: "Create Wire",
                      isFreeAction: true,
                      onClick: (event) => this.fetchExchangeRatesForWire(),
                    },
                    {
                      icon: (props) => (
                        <MTButton
                          variant="contained"
                          color="primary"
                          size="small"
                          className={classes.button}
                          startIcon={<FileCopyIcon />}
                        >
                          Copy Charge
                        </MTButton>
                      ),
                      tooltip: "Copy charge",
                      isFreeAction: true,
                      onClick: (event) => this.showCopyChargePanel(),
                    },
                    {
                      icon: (props) => (
                        <MTButton
                          variant="contained"
                          color="primary"
                          size="small"
                          className={classes.button}
                          startIcon={<AddBox />}
                        >
                          Charge
                        </MTButton>
                      ),
                      tooltip: "Add Charge",
                      isFreeAction: true,
                      onClick: (event) => this.showAddChargePanel(),
                    },
                    {
                      tooltip: "Delete selected charge",
                      icon: (props) => <DeleteOutline {...props} />,
                      onClick: (evt, data) =>
                        this.deleteEntityWireChargeConfig(data),
                    },
                  ]}
                  options={{
                    search: false,
                    exportButton: false,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 30],
                    padding: "dense",
                    showEmptyDataSourceMessage: true,
                    paginationType: "stepped",
                    selection: true,
                    emptyRowsWhenPaging: false,
                    tableLayout: "fixed",
                  }}
                />
              )}
            </GridItem>
          </GridContainer>
          <Dialog
            open={this.state.addChargePanelVisible}
            onClose={this.hideAddChargePanel}
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={this.hideAddChargePanel}
            >
              Add Charge
            </DialogTitle>
            <DialogContent>
              <ChargeForm
                masterCharges={this.state.masterCharges}
                fromDate={this.state.fromDate}
                toDate={this.state.toDate}
                terminalId={this.state.terminalId}
                accountId={this.state.acquirerAccountId}
                reloadData={this.reloadData}
              />
            </DialogContent>
          </Dialog>
          <Dialog
            open={this.state.exchangeRatePanelVisible}
            onClose={this.hideExchangeRatesPanel}
          >
            <DialogContent>
              <MaterialTable
                style={{ margin: "2em 0 0 0" }}
                title="Define Exchange rates"
                icons={this.tableIcons}
                columns={[
                  {
                    field: "fromCurrency",
                    title: "From currency",
                    editable: "never",
                  },
                  {
                    field: "toCurrency",
                    title: "To currency",
                    editable: "never",
                  },
                  {
                    field: "exchangeRate",
                    type: "numeric",
                    title: "Exchange rate",
                  },
                ]}
                data={this.state.exchangeRates}
                editable={{
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        const dataUpdate = [...this.state.exchangeRates];
                        const index = oldData.tableData.id;
                        dataUpdate[index] = newData;
                        this.setState({ exchangeRates: dataUpdate });
                        resolve();
                      }, 1000);
                    }),
                }}
                actions={[
                  {
                    icon: (props) => (
                      <MTButton
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                        startIcon={<AddBox />}
                      >
                        Proceed
                      </MTButton>
                    ),
                    tooltip: "Create Wire",
                    isFreeAction: true,
                    onClick: (event) => this.generateWire(),
                  },
                ]}
                options={{
                  search: false,
                  exportButton: false,
                  padding: "default",
                  showEmptyDataSourceMessage: true,
                  selection: false,
                  actionsColumnIndex: -1,
                  tableLayout: "auto",
                  emptyRowsWhenPaging: false,
                }}
              />
              <Paper style={{ margin: "1em", padding: "1em" }}>
                {/*  <Grid style={{marginTop: '2em', marginBottom: '4em'}} container>
			  	  	 <Grid item xs={12}>
					    <FormControl className={classes.formControl}>
						  <InputLabel id="previousBalanceLabel" htmlFor="previousBalance">Previous Balance (USD)</InputLabel>
						  <Input id="previousBalance" name="previousBalance" onChange={this.handleChange}/>	
						</FormControl>						
					 </Grid>
					</Grid>*/}

                <Grid
                  container
                  style={{ marginTop: "1em", marginBottom: "2em" }}
                >
                  <Grid xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        autoOk
                        format="dd MMM yyyy"
                        label="Declined from"
                        value={this.state.declinedFromObj}
                        onChange={(val) =>
                          this.setState({
                            declinedFromObj: val,
                            declinedFrom: format(val, "yyyy-MM-dd"),
                          })
                        }
                      />
                      <TimePicker
                        label="Declined From Time"
                        views={["hours", "minutes", "seconds"]}
                        value={this.state.declinedFromTime}
                        format="HH:mm:ss"
                        onChange={(val) =>
                          this.setState({
                            declinedFromTime: val,
                            declinedFromTimeStr: format(val, "HH:mm:ss"),
                          })
                        }
                      ></TimePicker>

                      <DatePicker
                        autoOk
                        format="dd MMM yyyy"
                        label="Declined To"
                        ampm="false"
                        value={this.state.declinedToObj}
                        onChange={(val) =>
                          this.setState({
                            declinedToObj: val,
                            declinedTo: format(val, "yyyy-MM-dd"),
                          })
                        }
                      />
                      <TimePicker
                        label="Declined To Time"
                        views={["hours", "minutes", "seconds"]}
                        value={this.state.declinedToTime}
                        format="HH:mm:ss"
                        onChange={(val) =>
                          this.setState({
                            declinedToTime: val,
                            declinedToTimeStr: format(val, "HH:mm:ss"),
                          })
                        }
                      >
                        {" "}
                      </TimePicker>
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        autoOk
                        format="dd MMM yyyy"
                        label="Chargeback from"
                        ampm="false"
                        value={this.state.chbkFromObj}
                        onChange={(val) =>
                          this.setState({
                            chbkFromObj: val,
                            chbkFrom: format(val, "yyyy-MM-dd"),
                          })
                        }
                      />
                      <TimePicker
                        label="Chargeback From Time"
                        views={["hours", "minutes", "seconds"]}
                        value={this.state.chbkFromTime}
                        format="HH:mm:ss"
                        onChange={(val) =>
                          this.setState({
                            chbkFromTime: val,
                            chbkFromTimeStr: format(val, "HH:mm:ss"),
                          })
                        }
                      ></TimePicker>
                      <DatePicker
                        autoOk
                        format="dd MMM yyyy"
                        label="Chargeback To"
                        ampm="false"
                        value={this.state.chbkToObj}
                        onChange={(val) =>
                          this.setState({
                            chbkToObj: val,
                            chbkTo: format(val, "yyyy-MM-dd"),
                          })
                        }
                      />
                      <TimePicker
                        label="Chargeback To Time"
                        views={["hours", "minutes", "seconds"]}
                        value={this.state.chbkToTime}
                        format="HH:mm:ss"
                        onChange={(val) =>
                          this.setState({
                            chbkToTime: val,
                            chbkToTimeStr: format(val, "HH:mm:ss"),
                          })
                        }
                      ></TimePicker>
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        autoOk
                        format="dd MMM yyyy"
                        label="Refund from"
                        value={this.state.refundFromObj}
                        onChange={(val) =>
                          this.setState({
                            refundFromObj: val,
                            refundFrom: format(val, "yyyy-MM-dd"),
                          })
                        }
                      />
                      <TimePicker
                        label="Refund From Time"
                        views={["hours", "minutes", "seconds"]}
                        value={this.state.refundFromTime}
                        format="HH:mm:ss"
                        onChange={(val) =>
                          this.setState({
                            refundFromTime: val,
                            refundFromTimeStr: format(val, "HH:mm:ss"),
                          })
                        }
                      ></TimePicker>
                      <DatePicker
                        autoOk
                        format="dd MMM yyyy"
                        label="Refund To"
                        value={this.state.refundToObj}
                        onChange={(val) =>
                          this.setState({
                            refundToObj: val,
                            refundTo: format(val, "yyyy-MM-dd"),
                          })
                        }
                      />
                      <TimePicker
                        label="Refund To Time"
                        views={["hours", "minutes", "seconds"]}
                        value={this.state.refundToTime}
                        format="HH:mm:ss"
                        onChange={(val) =>
                          this.setState({
                            refundToTime: val,
                            refundToTimeStr: format(val, "HH:mm:ss"),
                          })
                        }
                      ></TimePicker>
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        autoOk
                        label="Release reserve from"
                        ampm="false"
                        format="dd MMM yyyy"
                        value={this.state.releaseFrom}
                        onChange={(val) => this.setState({ releaseFrom: val })}
                      />
                      &nbsp; &nbsp; &nbsp;
                      <DatePicker
                        autoOk
                        label="Release reserve To"
                        ampm="false"
                        format="dd MMM yyyy"
                        value={this.state.releaseTo}
                        onChange={(val) => this.setState({ releaseTo: val })}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
              </Paper>
            </DialogContent>
          </Dialog>
          {this.state.showCharges && (
            <MaterialTable
              style={{ margin: "2em 0 0 0" }}
              title="Wires"
              icons={this.tableIcons}
              columns={[
                { field: "settlementAmount", title: "Settle amt" },
                { field: "currency", title: "Currency" },
                { field: "successTxnAmount", title: "Success amt" },
                { field: "totalCharges", title: "Charges" },
                { field: "paymentSettled", title: "Settled" },
                {
                  field: "validFrom",
                  title: "From",
                  render: (rowData) => (
                    <Typography noWrap="true" variant="caption">
                      {format(new Date(rowData.validFrom), "dd MMM yyyy")}
                    </Typography>
                  ),
                },
                {
                  field: "validTo",
                  title: "To",
                  render: (rowData) => (
                    <Typography noWrap="true" variant="caption">
                      {format(new Date(rowData.validTo), "dd MMM yyyy")}
                    </Typography>
                  ),
                },
                { field: "approved", title: "Approved" },
              ]}
              data={this.state.wires}
              actions={[
                {
                  tooltip: "Delete selected wire",
                  icon: (props) => <DeleteOutline {...props} />,
                  onClick: (evt, rowData) => this.deleteWire(rowData.wireId),
                },
                {
                  tooltip: "Approve wire",
                  icon: (props) => <Check {...props} />,
                  onClick: (evt, rowData) => this.approveWire(rowData.wireId),
                },
                {
                  tooltip: "Report PDF",
                  icon: (props) => <PictureAsPdfIcon {...props} />,
                  onClick: (evt, rowData) => this.showPdf(rowData.wireId),
                },
                {
                  tooltip: "Transactions XLS",
                  icon: (props) => (
                    <Avatar
                      variant="square"
                      className={classes.small}
                      src="xls-icon.png"
                      {...props}
                    />
                  ),
                  onClick: (evt, rowData) => this.showXLS(rowData.wireId),
                },
              ]}
              options={{
                search: false,
                exportButton: false,
                pageSize: 10,
                pageSizeOptions: [10, 20, 30],
                padding: "default",
                showEmptyDataSourceMessage: true,
                selection: false,
                actionsColumnIndex: -1,
                tableLayout: "auto",
                emptyRowsWhenPaging: false,
              }}
              detailPanel={[
                {
                  tooltip: "Show details",
                  render: (rowData) => {
                    return (
                      <Table size="small">
                        <TableHead>
                          <TableRow className={classes.detailhead}>
                            {this.headCells2.map((head) => (
                              <StyledTableCell key={head.id} align="left">
                                {head.label}
                              </StyledTableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rowData.wireDetails.map((txn, index) => (
                            <TableRow key={index}>
                              <TableCell align="left">
                                {txn.chargeName}
                              </TableCell>
                              <TableCell align="left">
                                {txn.chargeValue}
                              </TableCell>
                              <TableCell align="left">{txn.amount}</TableCell>
                              <TableCell align="left">
                                {txn.chargeDirection}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    );
                  },
                },
              ]}
            />
          )}
          <Dialog
            open={this.state.copyChargePanelVisible}
            onClose={this.hideCopyChargePanel}
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={this.hideCopyChargePanel}
            >
              Copy charges
            </DialogTitle>
            <DialogContent>
              <FormControl
			    sx={{ minWidth: "250px", marginBottom: "5px" }}
                fullWidth>
                <InputLabel id="copyTerminalSelectLable">Copy from Terminal:</InputLabel>
                <Select
                  value={this.state.copyTerminalId}
                  MenuProps={this.MenuProps}
                  onChange={this.handleChange}
                  name="copyTerminalId"
                  labelId="copyTerminalSelectLable"
                  id="copyTerminalIdSelect"
                >
                  <MenuItem key="" value="">
                    &nbsp;{" "}
                  </MenuItem>
                  {this.state.merchantTerminals &&
                    this.state.merchantTerminals.map((mt) => (
                      <MenuItem key={mt.terminalId} value={mt.terminalId} dense>
                        {mt.label + "-" + mt.terminalCardType}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Box align="center">
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.button}
                  onClick={this.copyTerminalCharges}
                >
                  Copy
                </Button>
              </Box>
            </DialogContent>
          </Dialog>
        </Paper>
        <Backdrop open={this.state.progressOpen} className={classes.backdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </React.Fragment>
    );
  }
}
export default withStyles(useStyles)(WireManagementComponent);
