import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";

import DoneIcon from "@material-ui/icons/Done";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import LiveHelpOutlinedIcon from "@material-ui/icons/LiveHelpOutlined";

// @mui/material components
import FormControl from "@mui/material/FormControl";
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';

import { Status } from "../../../util/constants";

import useStyles from "./customInputStyle";

export default function CustomInput(props) {
  const classes = useStyles();
  const {
    formControlProps,
    title,
    field,
    labelProps,
    inputProps,
    error,
    helperType,
    helperText,
    white,
    inputRootCustomClasses,
    success,
  } = props;

  //console.log("=========> ", inputProps);
  
  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error,
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white,
  });
  const marginTop = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined,
  });
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white,
  });
  var formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.formControl
    );
  } else {
    formControlClasses = classes.formControl;
  }
  return (
    <FormControl {...formControlProps} className={formControlClasses}>      
      <Input
        classes={{
          input: inputClasses,
          root: marginTop,
          disabled: classes.disabled,
          underline: underlineClasses,
        }}
        id={field}
        {...inputProps}
      />
      {helperType !== undefined && helperType !== "" ? (
        helperType === Status.ERROR ? (
          <div
            className={classes.helperLabelRoot + " " + classes.helperLabelError}
          >
            <PriorityHighIcon
              className={classes.icon + " " + classes.helperLabelError}
            />
            {helperText}
          </div>
        ) : helperType === Status.SUCCESS ? (
          <div
            className={
              classes.helperLabelRoot + " " + classes.helperLabelSuccess
            }
          >
            <DoneIcon
              className={classes.icon + " " + classes.helperLabelSuccess}
            />
            {helperText}
          </div>
        ) : (
          <div className={classes.helperLabelRoot}>
            <LiveHelpOutlinedIcon className={classes.icon} />
            {helperText}
          </div>
        )
      ) : null}
    </FormControl>
  );
}

CustomInput.propTypes = {
  title: PropTypes.node,
  labelProps: PropTypes.object,
  field: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  helperType: PropTypes.oneOf([Status.INFO, Status.SUCCESS, Status.ERROR, ""]),
  helperText: PropTypes.string,
  success: PropTypes.bool,
  white: PropTypes.bool,
};
