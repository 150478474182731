import React, { Component } from 'react';
import { Link, BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import MenuComponent from './screens/auth/MenuComponent';
import ApiService from './ApiService';
import { isMobile } from 'react-device-detect';
import Avatar from '@material-ui/core/Avatar';

import { PowerSettingsNew as PowerSettingsNewIcon, Menu, ArrowBack } from '@material-ui/icons';

import Home from './Home';
import LoginComponent from './screens/user/Login/LoginComponent';
import LogoutComponent from './screens/auth/LogoutComponent';
import AuthenticatedRoute from './screens/auth/AuthenticatedRoute';
import fraudRulesManagement from './fraudRulesManagementComponent';
import AcquirerManagement from './screens/acquirer/AcquirerManagementComponent';
import AcquirerAccountManagement from './screens/acquirer/AcquirerAccountComponent';
import AffiliateManagement from './screens/acquirer/AffiliateManagementComponent';
import UserManagement from './screens/user/UserManagementComponent';
import MerchantManagement from './screens/merchant/merchants/MerchantComponent';
import MerchantTerminal from './screens/merchant/terminal/MerchantTerminalComponent';
import MerchantOnboarding from './screens/merchant/onboarding/MerchantOnboardingComponent';
import WireManagement from './screens/reports/WireManagementComponent';
import RollingReserveComponent from './screens/reports/RollingReserveComponent';
import TxnManagement from './TransactionManagementComponent';
import MerchantTransactions from './screens/merchant/MerchantTransactionsComponent';
import MerchantTransactionSummary from './screens/merchant/MerchantTransactionSummaryComponent';
import ChargebackTransactions from './ChargebackManagementComponent';
import CardWhitelist from './CardWhitelistComponent';
import Dashboard from './screens/dashboard/DashboardComponent';
import ChargebackReportComponent from './screens/reports/ChargebackReportComponent';
import MerchantChargebackComponent from './screens/merchant/MerchantChargebackComponent';
import SessionTimeout from './SessionTimeout';

import Button from './components/Custom/Button/Button';
import { IconButton } from '@mui/material';

let logo = require ('./environment/client/logo/' + process.env.REACT_APP_CLIENT + '-logo.png');

function Copyright() {
	return (
		<div style={{ position: "fixed", width: "90%", bottom: 5, backgroundColor: "#FFF"}}>
			<Typography variant="body2" color="textSecondary" align="center">
				{'Copyright ©'}
				<Link color="inherit" to={`https://${process.env.REACT_APP_CLIENT}.com/`}>
					{process.env.REACT_APP_CLIENT}.com
				</Link>{' '}
				{2020}{'.'}
			</Typography>
		</div>
	);
}

const drawerWidth = 240;

const useStyles = (theme) => ({
	title: {
		color: theme.palette.primary.main,
	},
	merchantName: {
		marginRight: 36,
		color: theme.palette.primary.dark,
	}
});

class InstructorApp extends Component {

	constructor(props) {
		super(props)
		this.state = {
			open: false,
			merchantName: null,
			loginSuccess: false,
		}
		this.toggleSideBar = this.toggleSideBar.bind(this)
		this.handleSuccessLogin = this.handleSuccessLogin.bind(this)
	}

	toggleSideBar = (action) => {
		this.setState({ open: action })
	};

	onLogout = (event) => {
		this.toggleSideBar(false);
		this.setState({loginSuccess: false});
		ApiService.logout();
		window.location.href = "/";
	}

	handleSuccessLogin() {
		var username= ApiService.fetchLoggedInMerchant();
		this.setState({
			merchantName: username, 
			loginSuccess: true, 
			open: ApiService.isUserLoggedIn() ? !isMobile : false 
		}) 
	}

	componentDidMount() {
		this.setState({ open: ApiService.isUserLoggedIn() ? !isMobile : false })
	}

	render() {
		const { classes } = this.props;

		//console.log ("logo", logo);
		console.log ("this.state.open", this.state.open);
		const menuWidth = this.state.open ? drawerWidth : (isMobile ? 0 : 62);
		const logoWidth = process.env.REACT_APP_LOGO_WIDTH ? process.env.REACT_APP_LOGO_WIDTH : "60";

		return (
			<Router>
				<Box sx={{ display: 'flex', backgroundColor: "#FFF" }}>
					<CssBaseline />
					<SessionTimeout isAuthenticated={ApiService.isUserLoggedIn()} logOut={this.onLogout} />
					<AppBar position="fixed" sx={{ 
							width: "100vw",
							zIndex: (theme) => theme.zIndex.drawer + 1,
							transition: (theme) => theme.transitions.create(["margin"], {
								easing: (theme) => theme.transitions.easing.sharp,
								duration: (theme) => theme.transitions.duration.leavingScreen,
							}),
							backgroundColor: "#FFF",
						}}>
						<Toolbar>
							{this.state.loginSuccess && 
								<Button color="transparent" size="lg" style={{color: "#777"}} round justIcon onClick={() => this.toggleSideBar(!this.state.open)}>
									{this.state.open ? <ArrowBack/> : <Menu/>}
								</Button>
							}
							<Typography variant="subtitle2" component="div" sx={{ flexGrow: 1 }} className={classes.title} style={{display: "flex", alignItems: "center"}}>
								<img src={logo} alt={process.env.REACT_APP_CLIENT} width={logoWidth} />
								{(!isMobile || !this.state.loginSuccess) && <>&nbsp;&nbsp;&nbsp; Admin Dashboard</>}
							</Typography>
							{!isMobile && 
								<Typography component="h3" variant="subtitle1" noWrap className={classes.merchantName}>
									{this.state.merchantName && 'User : ' + this.state.merchantName}
								</Typography>
							}
							{this.state.loginSuccess && 
								<Button color="transparent" size="lg" style={{color: "#777"}} round justIcon onClick={this.onLogout}>
										<PowerSettingsNewIcon />
								</Button>}
						</Toolbar>
					</AppBar>
					{this.state.loginSuccess && 
					<Drawer
						variant="permanent"
						sx={{
							width: menuWidth,
							flexShrink: 0,
							whiteSpace: 'nowrap',
							[`& .MuiDrawer-paper`]: { width: menuWidth, boxSizing: 'border-box' },
						}}
					>
						<Toolbar />
						<MenuComponent menuWidth={menuWidth} onClick={() => isMobile ? this.toggleSideBar(false) : {}}/>
					</Drawer>
					}
					<Box component="main" sx={{ flexGrow: 1, p: 3, height: '100vh', width: "100%", overflow: 'auto' }}>
						<Toolbar />
						<Switch>
							<Route path="/" exact render={(props) => <LoginComponent {...props} handler={this.handleSuccessLogin} />} />
							<AuthenticatedRoute path="/logout" exact component={LogoutComponent} />
							<AuthenticatedRoute path="/users" exact component={UserManagement} />
							<AuthenticatedRoute path="/merchantOnboarding" exact component={MerchantOnboarding} />
							
							<AuthenticatedRoute path="/acquirerManagement" exact component={AcquirerManagement} />
							<AuthenticatedRoute path="/acquirerAccountManagement" exact component={AcquirerAccountManagement} />
							
							<AuthenticatedRoute path="/affiliateManagement" exact component={AffiliateManagement} />							
							
							<AuthenticatedRoute path="/merchant" exact component={MerchantManagement} />
							<AuthenticatedRoute path="/merchantTerminal" exact component={MerchantTerminal} />
							<AuthenticatedRoute path="/merchantChargeback" exact component={MerchantChargebackComponent} />

							<AuthenticatedRoute path="/fraudRules" exact component={fraudRulesManagement} />
							
							<AuthenticatedRoute path="/wireManagement" exact component={WireManagement} />
							<AuthenticatedRoute path="/rollingReserve" exact component={RollingReserveComponent} />
							<AuthenticatedRoute path="/chargebackReport" exact component={ChargebackReportComponent} />							

							<AuthenticatedRoute path="/transactions" exact component={MerchantTransactions} />
							<AuthenticatedRoute path="/transactionsSummary" exact component={MerchantTransactionSummary} />
							<AuthenticatedRoute path="/txnManagement" exact component={TxnManagement} />
							<AuthenticatedRoute path="/cardWhitelist" exact component={CardWhitelist} />
							<AuthenticatedRoute path="/chargebacks" exact component={ChargebackTransactions} />
							
							<AuthenticatedRoute path="/dashboard" exact component={Dashboard} />
							<AuthenticatedRoute path="/home" exact component={Home} />
						</Switch>
						<Box mt={2}>
							<Copyright />
						</Box>
					</Box>
				</Box>
			</Router>
		);
	}
}

export default withStyles(useStyles)(InstructorApp)
