import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import {Divider, Typography, Paper, Grid,InputLabel, Select, MenuItem, Button, FormControl, } from '@material-ui/core';
import ApiService from './ApiService';
import MaterialTable from './components/MaterialTable';
import TableIcons from './screens/common/TableLogoComponent';
import { Alert } from '@mui/material';
import {subDays, format} from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import Tooltip from '@material-ui/core/Tooltip';
import Backdrop from '@material-ui/core/Backdrop';
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
 import TablePagination from '@material-ui/core/TablePagination';

import {
  MuiPickersUtilsProvider,
  DatePicker
} from '@material-ui/pickers';
import GridContainer from './components/Custom/Grid/GridContainer';
import GridItem from './components/Custom/Grid/GridItem';

const useStyles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
  tablewraptext: {
	textOverflow: 'ellipsis', 
    overflow: 'hidden', 
    whiteSpace: 'nowrap',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
	formControl: { 
    marginLeft: 20,
    minWidth: 120,
    maxWidth: 300,
  },
searchbar: {
	margin: theme.spacing(2),
  },
});

class ChargebackManagementComponent extends Component {
	constructor(props) {
        super(props)
        this.state = {
            transactions: [],
			merchantTerminals: [],
			terminalId: null,
			responsemessage: null,
			failed: false,
			success: false,
			fromDate: subDays(new Date(),180),
			toDate: new Date(),
			loading: false,
			totalRow: 0,
			numberRowPerPage: 20,
		    pageNumber:0,
			rowsPerPageOptions: [100,200,300],
		}	
	   this.reloadTransactions = this.reloadTransactions.bind(this); 
	   this.handleChange = this.handleChange.bind(this); 
	   this.fetchMerchantTerminals = this.fetchMerchantTerminals.bind(this);
	   this.handleClose = this.handleClose.bind(this);
	   this.searchTransactions = this.searchTransactions.bind(this);
	   this.handleChangeRowPerPage = this.handleChangeRowPerPage.bind(this);
	   this.handleChangePage = this.handleChangePage.bind(this);
	}
	handleChange(event) {
        this.setState(
            {
                [event.target.name]
                    : event.target.value
            }
        )
    }
	componentDidMount() {
        {/* this.reloadTransactions();*/}
		this.fetchMerchantTerminals();
    }
	fetchMerchantTerminals(){
		ApiService.fetchMerchantTerminals()
			.then((res)=> {
				this.setState({merchantTerminals: res.data})			
		}) 
	}
	handleClose() {
		this.setState({loading: false});
	}
	searchTransactions() {
		this.setState({pageNumber: 0});
		if(this.state.terminalId == null ) {
			this.setState({responsemessage:"Please select Merchant Terminal",failed: true});
			return;
		}
		this.reloadTransactions(0);
	}
	handleChangePage(e, pageNo) {
		this.setState({pageNumber: pageNo});
		this.reloadTransactions(pageNo);
	}
	handleChangeRowPerPage(data) {
		
	}
	reloadTransactions(pageNo) { 
		this.setState({loading: true});
        ApiService.fetchChargebackTransactions(format(this.state.fromDate,'yyyyMMdd'), 
        	format(this.state.toDate,'yyyyMMdd'), this.state.terminalId, pageNo, this.state.numberRowPerPage)
            .then((res) => {
                this.setState({totalRow: res.data.totalRow, result: res.data.chbkTransactions, merchantId: res.data.merchantId,loading: false, failed: false})
            }).catch((e) => {
				
			});
    }
	TerminalMenuProps = {
	  PaperProps: {
	    style: {
	      maxHeight: this.ITEM_HEIGHT * 5.5 + this.ITEM_PADDING_TOP,
	      width: 500,
	    },
	  },
	};
	render(){
		const { classes } = this.props;
		return (
			 <Paper style={{margin: '1em', padding: '1em'}}>
			   <Typography component="h2" variant="h6" color="primary" gutterBottom>
			      Chargeback Transactions
			   </Typography>
				{this.state.failed &&  <Alert severity="error" elevation={0}> {this.state.responsemessage}</Alert> }
				{this.state.success &&  <Alert severity="success" elevation={0}> {this.state.responsemessage}</Alert> }
			   <Divider light className={classes.searchbar}/>
			<GridContainer>
            <GridItem xs={12} sm={12} md={6} lg={6}>				  
			  <MuiPickersUtilsProvider utils={DateFnsUtils} >
			  	<GridContainer>
                  	<GridItem xs={6} sm={6} md={6} lg={6}>
						  <DatePicker autoOk 
							label="From"
							format="MM/dd/yyyy"
							value={this.state.fromDate} 
							onChange={(val)=> this.setState({fromDate: val})} />
							&nbsp;	&nbsp;	&nbsp;
					</GridItem>
                  	<GridItem xs={6} sm={6} md={3} lg={3}>
					<DatePicker autoOk
					          label="To"
					          format="MM/dd/yyyy"
					          value={this.state.toDate}
					          onChange={(val) =>  this.setState({toDate: val})}
					        />
						</GridItem>
                	</GridContainer>
			  </MuiPickersUtilsProvider>
			  </GridItem>
            <GridItem xs={12} sm={12} md={2} lg={2}>
			  <FormControl className={classes.formControl}>
					  <InputLabel id="merchantTerminalSelectLabel">Terminal</InputLabel>
					  <Select value={this.state.merchantTerminalId}  MenuProps={this.TerminalMenuProps}
					  		onChange={this.handleChange} name="terminalId"
					  		labelId="merchantTerminalSelectLabel" id="merchantTerminalSelect"
	 					>
						<MenuItem key="" value="">&nbsp; </MenuItem>	
						 {	this.state.merchantTerminals && this.state.merchantTerminals.map((mt) =>
						  	 <MenuItem key={mt.terminalId} value={mt.terminalId} dense>{mt.label + "-" + mt.terminalCardType}</MenuItem>
						)}
					  </Select>
			  </FormControl>
			</GridItem>
            <GridItem xs={12} sm={12} md={2} lg={2}>
			  	  <Grid item xs={2}>
			  	    <Button
				        variant="contained"
				        color="primary"
				        size="small"
				        className={classes.button}
				        startIcon={<SearchIcon />}
						onClick={this.searchTransactions}
				      >
				        Search
				    </Button>
				    <Backdrop open={this.state.loading} className={classes.backdrop} onClick={this.handleClose}>
				    <CircularProgress color="inherit" /></Backdrop>
				    </Grid>
			  	
			</GridItem>
            <GridItem xs={12} sm={12} md={12} lg={12}>
				<MaterialTable 
				      title="" 
					  icons={TableIcons.icons}
					   components={{
						Pagination: props => (
	                      <TablePagination
	                           {...props}
	                          rowsPerPageOptions={[]}
                      		  rowsPerPage={this.state.numberRowPerPage}
	                          count={this.state.totalRow}
		                      page={this.state.pageNumber}
		                      onChangePage={(e, page) =>
		                        this.handleChangePage(e, page)
		                      }
		                      onRowsPerPageChange={event => {
		                        //props.onChangeRowsPerPage(event);
		                        this.handleChangeRowPerPage(event.target.value);
		                      }}
		                    />
		                  ),
					  }}
				      columns={[ 
				       		 { field: 'terminalId', title: 'Terminal',render: rowData => <>
									<Tooltip title={rowData.terminalId} interactive>
										 <InfoOutlinedIcon/>
									</Tooltip>
								</>
							},
							{ field: 'txnCreated', title: 'Txn Date', type:'date',  render: rowData => <Typography noWrap="true" variant="caption">{format(rowData.txnCreated,'dd MMM yyyy')}</Typography> },
					        { field: 'chbkCreated', title: 'Chbk Date', type:'date',  render: rowData => <Typography noWrap="true" variant="caption">{format(rowData.chbkCreated,'dd MMM yyyy')}</Typography> },
					        { field: 'transactionId' , title: 'Txn Id', render: rowData => <>
									<Tooltip title={rowData.transactionId} interactive>
										 <InfoOutlinedIcon/>
									</Tooltip>
								</>},
							{ field: 'merchantOrderNo' , title: 'Ord No', render: rowData => <>
									<Tooltip title={rowData.merchantOrderNo} interactive>
										 <InfoOutlinedIcon/>
									</Tooltip>
								</>},						
					        {field: 'cardType', title: 'Card Type',cellStyle: {fontSize: 11,fontFamily: "sans-serif",},},					      
							{field: 'amount', title: 'Amount', type: 'numeric', render: rowData=><Typography variant="caption">{rowData.amount && rowData.amount.toFixed(2)}</Typography> },
							{field: 'currency', title: 'Currency', cellStyle: {fontSize: 11,fontFamily: "sans-serif",},},
							{field: 'customerEmail', title: 'Cust Email', cellStyle: {fontSize: 11,fontFamily: "sans-serif",},},
							{field: 'code', title: 'Code', cellStyle: {fontSize: 11,fontFamily: "sans-serif",},},	 
				      ]}
				      data={this.state.result}
					   
					  options={{
						search:true,
				        exportButton: false,
						pageSize: 20,
						pageSizeOptions: [20,40,80],
						padding: 'dense',
						initialPage: 0,
						pageSize: this.state.numberRowPerPage,
						showEmptyDataSourceMessage: true,
						emptyRowsWhenPaging: false,
				      }}				     
				    />
				</GridItem>
          	</GridContainer>
		</Paper>
			
		);
	}
}

export default withStyles(useStyles)(ChargebackManagementComponent);

	