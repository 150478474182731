import React, { useState } from "react";
import ApiService, { setToast } from '../../../ApiService';

import {CssBaseline, Avatar, Link, Grid,Typography, Container, 
    InputLabel, OutlinedInput, FormControl, CircularProgress, Paper, TextField} from '@mui/material';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Alert } from '@mui/material';
import { useSnackbar } from "notistack";

import Button from '../../../components/Custom/Button/Button';

import useStyles from "./styles";
import GridContainer from "../../../components/Custom/Grid/GridContainer";
import GridItem from "../../../components/Custom/Grid/GridItem";
import { Box } from "@material-ui/core";

export default function LoginComponent(props) {
    var classes = useStyles();

    const { enqueueSnackbar: showSnackbar, closeSnackbar } = useSnackbar();
    var [username, setUsername] = useState(null);
    var [password, setPassword] = useState(null);
    var [hasLoginFailed, setHasLoginFailed] = useState(false);
    var [showSuccessMessage, setShowSuccessMessage] = useState(false);
    var [loading, setLoading] = useState(false);
    
    const handleChange = (event) => {
        if (event.target.name === "username") setUsername(event.target.value);
        else if (event.target.name === "password") setPassword(event.target.value);
    }
	
	const handleKeyPress = (ev) => {
		if (ev.key === 'Enter') loginClicked()
	}
	
    const loginClicked = () => {
        setToast(showSnackbar, closeSnackbar);
		setLoading(true);
        ApiService.executeJwtAuthenticationService(username, password)
            .then(res => {
                ApiService.registerSuccessfulLoginForJwt(username, res.data.auth_token)
                props.history.push(`/dashboard`);
				props.handler();
            }).catch(() => {
                setShowSuccessMessage(false);
                setHasLoginFailed(true);
                setLoading(false);
            })
    }

    return (<GridContainer>
                <GridItem xs={12} sm={12} md={3} lg={4}></GridItem>
                <GridItem xs={12} sm={12} md={6} lg={4} style={{ marginTop: "14px" }}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={12} style={{ marginTop: "4px" }} >
                            <div style={{justifyContent: "center", alignItems:"center", display: "flex"}} >
                                <Avatar style={{backgroundColor: "#FF5C93", width: "50px", height: "50px" }}>
                                    <LockOutlinedIcon />
                                </Avatar>
                            </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={12} style={{ marginTop: "4px" }}>
                            <div style={{justifyContent: "center", display: "flex"}} >
                                <Typography component="h1" variant="h5">Sign in</Typography>
                            </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={12} style={{ marginTop: "20px" }}>
                        {hasLoginFailed &&  <Alert severity="error" elevation={0}>Invalid Credentials !</Alert>}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={12} style={{ marginTop: "4px" }}>
                        <FormControl fullWidth >
                            <InputLabel required htmlFor="username">User name</InputLabel>
                            <OutlinedInput id="username" name="username" value={username} label="User Name" onChange={handleChange} />
                        </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={12} style={{ marginTop: "20px" }}>
                        <FormControl fullWidth >
                            <InputLabel required htmlFor="password">Password</InputLabel>
                            <OutlinedInput id="password" name="password" value={password} label="Password" onKeyPress={handleKeyPress} onChange={handleChange} type="password" />
                        </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={12} style={{ marginTop: "4px" }}>
                        {/*<FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        /> */}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={12} style={{ marginTop: "4px" }}>
                        <FormControl variant="outlined" className={classes.form}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                style={{marginTop: "15px", marginBottom: "15px", 
                                height: "40px", visibility: "visible"}}
                                onClick={loginClicked}                            
                            >
                                Sign In
                            </Button>
                        </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={12} style={{ marginTop: "4px" }}>
                            <Link href="#" variant="body2">Forgot password?</Link>
                        </GridItem>
                    </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12} style={{ marginTop: "4px" }}>
                    <div style={{justifyContent: "center", alignItems:"center", display: "flex"}} >
                        <Avatar alt="PCI" variant="square" src="pci.png" style={{width: "60px", height: "60px" }}></Avatar>
                    </div>
                </GridItem>
            </GridContainer>
    );
}
