import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material components

import useStyles from "./styles/cardStyle";

export default function Card(props) {
  const classes = useStyles();
  const { color, className, children, plain, carousel, ...rest } = props;
  const cardClasses = classNames({
    [classes.card]: true,
    [classes.cardPlain]: plain,
    [classes.cardCarousel]: carousel,
    [className]: className !== undefined,
    [classes[color]]: color
  });
  return (
    <div className={cardClasses} {...rest}>
      {children}
    </div>
  );
}

Card.propTypes = {
  color: PropTypes.oneOf([
    "gray",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "white",
    "transparent"
  ]),
  className: PropTypes.string,
  plain: PropTypes.bool,
  carousel: PropTypes.bool,
  children: PropTypes.node
};
