import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";

// @mui/material components
import Button from '@mui/material/Button';
import CustomTooltip from "../Tooltip/Tooltip";

import useStyles from "./buttonStyle";

const RegularButton = React.forwardRef((props, ref) => {
  const {
    color,
    round,
    children,
    fullWidth,
    disabled,
    simple,
    size,
    block,
    link,
    justIcon,
    className,
    toolTipTitle,
    isMobile,
    ...rest
  } = props;

  const classes = useStyles();

  const btnClasses = classNames({
    [classes.button]: true,
    [classes[size]]: size,
    [classes[color]]: color,
    [classes.round]: isMobile ? true : round,
    [classes.fullWidth]: fullWidth,
    [classes.disabled]: disabled,
    [classes.simple]: simple,
    [classes.block]: block,
    [classes.link]: link,
    [classes.justIcon]: isMobile ? true : justIcon,
    [className]: className,
  });

  return toolTipTitle ? (
    <CustomTooltip title={toolTipTitle} aria-label={toolTipTitle}>
      <Button {...rest} ref={ref} className={btnClasses}>
        {children}
      </Button>
    </CustomTooltip>
  ) : (
    <Button {...rest} ref={ref} className={btnClasses}>
      {children}
    </Button>
  );
});

RegularButton.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "white",
    "transparent",
  ]),
  size: PropTypes.oneOf(["sm", "lg"]),
  simple: PropTypes.bool,
  round: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  link: PropTypes.bool,
  justIcon: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  toolTipTitle: PropTypes.string,
  isMobile: PropTypes.bool,
};

export default RegularButton;
