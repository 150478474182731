import React, {Component} from 'react';
import { AttachFile } from '@material-ui/icons';
import { DropzoneArea } from 'material-ui-dropzone';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Input from '@material-ui/core/Input';
import Button from './components/Custom/Button/Button';
import ApiService from './ApiService';
import Grid from '@material-ui/core/Grid';
import Box from "@material-ui/core/Box";
import { Alert } from '@mui/material';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { showToast, Status } from "./ApiService";

const useStyles = theme => ({
	searchbar: {
		margin: theme.spacing(2),
	  },
	 root: {
		height: 500,
    	width:  200,
	},
	button: {
	    margin: theme.spacing(1),
	  },  
	formControl: { 
	    marginLeft: 10,
	    minWidth: 300,
	    maxWidth: 300,
  },

});
	
class TransactionManagementComponent extends Component {
	constructor(props) {
        super(props);
        this.state = {
            merchantId: null,
			selectedFile: null,
			configs: [],
			selectedConfig: null,
			fileSubmitIdx: 1
		}
		this.handleChange = this.handleChange.bind(this);
		this.onFileChangeHandler = this.onFileChangeHandler.bind(this);
		this.uploadFile = this.uploadFile.bind(this);
		this.fetchStatusUploadConfigs = this.fetchStatusUploadConfigs.bind(this);
	}
	handleChange(event) {
        this.setState(
            {
                [event.target.name]
                    : event.target.value
            }
        )
    }

	fetchStatusUploadConfigs() {
		ApiService.fetchAllStatusUploadConfigs().then((res) => {
			this.setState({configs: res.data});
		})
	}

	onFileChangeHandler = (files) => {
        this.setState({selectedFile: files[0]});
       
    };
	componentDidMount() {
		this.fetchStatusUploadConfigs();
	}

	uploadFile() {		
		const formData = new FormData();
        formData.append('files', this.state.selectedFile);
		if(this.state.selectedConfig != null && this.state.selectedConfig!='')
			formData.append('configStr', JSON.stringify(this.state.selectedConfig));
        ApiService.uploadTxnStatus(formData).then(res => {
			if(res.data.status == 'OK') {
				let idx = this.state.fileSubmitIdx;
				showToast({ type: Status.SUCCESS, title: "Status", description: "Transactions status uploaded successfully !, " + res.data.responseMessage});
				this.setState({selectedFile: null, fileSubmitIdx: ++idx});
			} else {
				showToast({ type: Status.ERROR, title: "Status", description: res.data.responseMessage});
			}
		});
	}

	render(){
		const { classes } = this.props;
		return (			
			<React.Fragment>
				<Paper style={{margin: '1em', padding: '1em', minHeight: 400,}}>
					   <Typography component="h2" variant="h6" color="primary" gutterBottom>
					      Transactions status management 
					   </Typography>
					<Typography variant="subtitle1" color="secondary">
					(Only Excel Files are allowed, If using CSV open and save as XLSX )	
					</Typography>
					<Divider light className={classes.searchbar}/>
					<Grid container>
						<Grid item xs={12}>
						<FormControl className={classes.formControl}>
							<InputLabel id="configSelectLabel">Select if uploading acquirer file: </InputLabel>
							<Select value={this.state.selectedConfig}
								onChange={this.handleChange} name="selectedConfig"
								labelId="configSelectLabel" id="configSelect"
							>
							<MenuItem key="" value="">&nbsp; </MenuItem>	
								{	this.state.configs && this.state.configs.map((c) =>
									<MenuItem key={c.id} value={c} dense>{c.acquirer.name + "-" + c.configType}</MenuItem>
							)}
							</Select>
						</FormControl>
						</Grid>
					</Grid>
					&nbsp;
					<DropzoneArea 
						key={this.state.fileSubmitIdx}
						Icon={AttachFile}
						dropzoneText={"Drag and drop xls/xlsx here or click to add file"}
						// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
						acceptedFiles={['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
						filesLimit={1}
						showPreviews={true}
						// showFileNames={true}
						showPreviewsInDropzone={false}
						//showFileNamesInPreview={true}
						useChipsForPreview={true}
						onChange={files => {
							console.log('Files:', files);
							this.onFileChangeHandler(files);
						}}
						onAlert={(message, variant) => console.log(`${variant}: ${message}`)}
					/>
					<Grid justifyContent="center" alignItems="center" container>
						<Grid item xs={2}>
							<Button  variant="contained" color="primary" size="small"
								className={classes.button} onClick={this.uploadFile}>Upload</Button>
						</Grid>
					</Grid>
				</Paper>
        	</React.Fragment>
		);
	}
}
export default withStyles(useStyles)(TransactionManagementComponent);