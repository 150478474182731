import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ApiService, {Status, showToast} from '../../ApiService';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Divider from '@material-ui/core/Divider';
import MaterialTable from '../../components/MaterialTable';
import AddIcon from '@material-ui/icons/Add';
import { Alert } from '@mui/material';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { forwardRef } from 'react';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import CustomTooltip from '../../components/Custom/Tooltip/Tooltip';
import { Button as MTButton } from '@mui/material';
import CustomDialog from '../../components/Custom/Dialog/CustomDialog';
import CustomInput from '../../components/Custom/CustomInput/CustomInput';
import GridContainer from '../../components/Custom/Grid/GridContainer';
import GridItem from '../../components/Custom/Grid/GridItem';

import ObjectUtil from '../../util/objectUtil';

const useStyles = theme => ({
  button: {
    margin: theme.spacing(1),
  },

  detailhead: {
	 fontSize: 12,
  },
  searchbar: {
	margin: theme.spacing(2),
  },
  formControl: { 
    marginLeft: 20,
    minWidth: 120,
    maxWidth: 300,
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: 'absolute',
	marginTop: 8,
    marginLeft: -65,
  },
});

const CustomTableRow = (props) => (
	<TableRow>
		<TableCell align="left">{props.label}</TableCell>
		<TableCell align="left">{props.component}</TableCell>
	</TableRow>);

const CustomTableRowGroup = (props => {
	const rows = [];
	props.rows.map(row => rows.push(<CustomTableRow label={row.label} component={row.component}/>));
	return rows;
});

const CustomTableRowComponentGroup = (props => {
	// console.log("CustomTableRowComponentGroup", props.newAcquirerValidation);
	const rows = [];
	props.rows.map((row, key) => {
		const fieldName = row.name;
		rows.push(<GridItem key={key} xs={12} sm={12} md={12} lg={12} >
			<CustomInput title={row.label} field={fieldName} formControlProps={{ fullWidth: true, margin: "dense", height: "45px" }}
				inputProps={{
					placeholder: row.label,
					value: props.newAcquirer[fieldName], name: fieldName, id: fieldName, 
					onChange: props.handleNewFieldChange
				}}
				helperType={props.newAcquirerValidation[fieldName] ? props.newAcquirerValidation[fieldName].helperType : ""}
				helperText={props.newAcquirerValidation[fieldName] ? props.newAcquirerValidation[fieldName].helperText : ""}
			/>
		</GridItem>);
	});
	return rows;
});


const newAcquirerObject = {
	code: "", name: "", number: "", signkey: "", 
	prodApiUrl: "", testApiUrl: "", prodRefundUrl: "", uatRefundUrl: ""
};

class AcquirerManagementComponent extends Component {
	constructor(props) {
        super(props)
        this.state = { 
			failed: false,
			success: false,
			responsemessage: null,
			acquirers: [],
			selectedAcquirer: null,
			acquirerPanelVisible: false,
			showAddAcquirer: false,
			newAcquirer: ObjectUtil.DeepClone(newAcquirerObject),
			newAcquirerValidation: {},
		}
		this.handleChange = this.handleChange.bind(this);
		this.showAcquirerDialog = this.showAcquirerDialog.bind(this);
		this.hideAcquirerDialog = this.hideAcquirerDialog.bind(this);
		this.fetchAllAcquirers = this.fetchAllAcquirers.bind(this);
		this.handleNewFieldChange = this.handleNewFieldChange.bind(this);
		this.saveNewAcquirer = this.saveNewAcquirer.bind(this);		
	}

	handleChange(event) {
        this.setState({ [event.target.name] : event.target.value })
    }

	handleNewFieldChange(event) {
		const acquirer = {...this.state.newAcquirer};
		acquirer[event.target.name] = event.target.value;
		// console.log("acquirer", acquirer);
        this.setState({newAcquirer: acquirer});
    }	 

	componentDidMount() {
		this.fetchAllAcquirers();
	}
	fetchAllAcquirers() {
		ApiService.fetchAllAcquirers().then((res)=> {
			this.setState({acquirers: res.data})	
		}).catch((e)=> {});
	}
	
	showAcquirerDialog(data) {
		this.setState({selectedAcquirer: data[0], acquirerPanelVisible: true});
	}
	hideAcquirerDialog() {
		this.setState({acquirerPanelVisible: false});
	}
	saveNewAcquirer() {
		if (ObjectUtil.isNullorEmpty(this.state.newAcquirer.code)) {
			this.setState({newAcquirerValidation: {code: {helperType: Status.ERROR, helperText: "Code is required"}}});
		}
		else if (ObjectUtil.isNullorEmpty(this.state.newAcquirer.name)) {
			this.setState({newAcquirerValidation: {name: {helperType: Status.ERROR, helperText: "Name is required"}}});
		}
		else {
			ApiService.addNewAcquirer(this.state.newAcquirer).then(res => {
				if(res.data.status === 'OK') {
					showToast({ type: Status.SUCCESS, title: "Status", description: "Acquirer added successfully. " + res.data.responseMessage});
					this.setState({
						showAddAcquirer: false,
						newAcquirer: ObjectUtil.DeepClone(newAcquirerObject),
					}, () => this.fetchAllAcquirers());
				} else {
					showToast({ type: Status.ERROR, title: "Status", description: res.data.responseMessage});
				}
			});
		}
	}

	tableIcons = {
		    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
		    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
		    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
		    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
		    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
		    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
		    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
		    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
		    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
		    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
		    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
		    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
		    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
		    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
		    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
		    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
		    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
		};
	render(){
		const { classes } = this.props; 
		const {
			selectedAcquirer, acquirers, acquirerPanelVisible, showAddAcquirer, newAcquirer, 
			newAcquirerValidation
		} = this.state;

		console.log("selectedAcquirer, newAcquirerValidation", selectedAcquirer, newAcquirerValidation);

		return (
			<React.Fragment>
				<Paper style={{margin: '1em', padding: '1em'}}>
					   <Typography component="h2" variant="h6" color="primary" gutterBottom>
					      Acquirer management
					   </Typography>
					<Divider light className={classes.searchbar}/>
					{this.state.failed &&  <Alert severity="error" elevation="0"> {this.state.responsemessage}</Alert> }
					{this.state.success && <Alert severity="success" elevation="0">{this.state.responsemessage}</Alert>}
					<MaterialTable
					  style={{margin: '1em 0 0 0'}}
				      title="Acquirer accounts" 
					  icons={this.tableIcons}
				      columns={[ 
				        { field: 'acquirerId',numeric: false, title: 'Id',render: rowData => <>
							<CustomTooltip title={rowData.acquirerId} copy={true}><InfoOutlinedIcon/></CustomTooltip>
							</>
						},
						{ field: 'code' , numeric: false, title: 'Code'},
				        { field: 'name' ,numeric: false, title: 'Name'},
						{ field: 'number',numeric: false, title: 'Number'},
				      ]}
				      data={acquirers}
					  actions={[
						{
							tooltip: 'Show details',
							icon: (props)=> <MTButton  variant="contained" color="primary" size="small">Details</MTButton>,
							onClick: (evt, data) => this.showAcquirerDialog(data),
							isFreeAction: false
						},
						{
							tooltip: 'Add Acquirer',
							icon: (props)=> <MTButton  variant="contained" color="primary" size="small"><AddIcon />Acquirer</MTButton>,
							onClick: (evt, data) => this.setState({showAddAcquirer: true}),
							isFreeAction: true
						}
					  ]}
					  options={{
						search:false,
				        exportButton: false,
						pageSize: 30,
						pageSizeOptions: [15,30,45],
						padding: 'dense',
						showEmptyDataSourceMessage: true,
						selection: true,
						emptyRowsWhenPaging: false,
				      }}				     
				    />
					{selectedAcquirer &&
					<CustomDialog key="warningDialog"
						open={acquirerPanelVisible}
						title={`Acquirer details`}
						childNode={<TableContainer component={Paper}>
							<Table>
								<TableBody>
									<CustomTableRowGroup rows={[
										{label: "Acquirer Id", component: selectedAcquirer.acquirerId},
										{label: "Code", component: selectedAcquirer.code},
										{label: "Name", component: selectedAcquirer.name},
										{label: "Number", component: selectedAcquirer.number},
										{label: "Signkey", component: selectedAcquirer.signkey},
										{label: "Prod Api URL", component: selectedAcquirer.prodApiUrl},
										{label: "Test Api URL", component: selectedAcquirer.testApiUrl},
										{label: "Prod Refund Url", component: selectedAcquirer.prodRefundUrl},
										{label: "Test Refund Url", component: selectedAcquirer.uatRefundUrl}
									]} />
								</TableBody>
							</Table>
						</TableContainer>}
						//handleConfirm={() => this.onCancelClick(selectedRow)}
						handleCancel={() => this.setState({acquirerPanelVisible: false})}
						type={Status.NOTIFY}
						actionButtonColor={"primary"}
						// showFullWidth={true}
					/>}
					{showAddAcquirer &&
						<CustomDialog key="addDialog"
						open={showAddAcquirer}
						title={`Add Acquirer`}
						fullWidth={false}
						childNode={<GridContainer>
							<CustomTableRowComponentGroup 
								newAcquirer={newAcquirer} 
								handleNewFieldChange={this.handleNewFieldChange}
								newAcquirerValidation={newAcquirerValidation}
								rows={[
									{label: "Code", name: "code"},
									{label: "Name", name: "name"},
									{label: "Number", name: "number"},
									{label: "Signkey", name: "signkey"},
									{label: "Prod Api URL", name: "prodApiUrl"},
									{label: "Test Api URL", name: "testApiUrl"},
									{label: "Prod Refund URL", name: "prodRefundUrl"},
									{label: "Test Refund URL", name: "uatRefundUrl"}
								]} />								
						</GridContainer>}
						handleConfirm={() => this.saveNewAcquirer()}
						handleCancel={() => this.setState({showAddAcquirer: false})}
						type={Status.NOTIFY}
						actionButtonColor={"primary"}
						// showFullWidth={true}
					/>}
				</Paper>
			</React.Fragment>
			);
	}
}
export default withStyles(useStyles)(AcquirerManagementComponent);