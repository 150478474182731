import { makeStyles } from "@material-ui/core/styles";
import { isMobile } from "react-device-detect";

export default makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginLeft: "0px",
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
	buttonProgress: {
        color: theme.palette.primary.dark,
        top: '50%',
        left: '50%',
        position: 'absolute',
        marginTop: -12,
        marginLeft: -12,
    },
  wrapper: {
    margin: theme.spacing(1),
    marginTop: "50px",
    marginBottom: "10px",
    position: 'relative',
  },
}));